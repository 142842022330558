import _ from "lodash";

import { SidebarIcon } from "../components";

export const SBItemsHelper = {
  generateSidebarConfig(
    t,
    confRootPath,
    itemNamesArr,
    hasAdminOption = false,
    validationFieldsBySidebarItem = {},
    isJsonBase = false
  ) {
    const sidebarItems = itemNamesArr.map((itemName) => ({
      title: isJsonBase ? itemName : t(`${confRootPath}.${itemName}.sidebar`),
      href: `#enrollment-form-${itemName}`,
      key: _.camelCase(itemName),
      icon: SidebarIcon,
      validationFields: validationFieldsBySidebarItem[itemName] || [itemName]
    }));

    return hasAdminOption
      ? [
          {
            title: t(`${confRootPath}.support_options.sidebar`), // Use confRootPath + 'support_options'
            href: "#enrollment-form-support-options",
            key: "supportOptions",
            icon: SidebarIcon,
            validationFields: ["assistanceType"]
          },
          ...sidebarItems
        ]
      : sidebarItems;
  }
};

export default SBItemsHelper;
