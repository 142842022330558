import React from "react";
import { Alert, Divider, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

import { commonLabels } from "./fieldLabels";
import { formSections } from "./sections";
import { InputItem, StateItem, PhoneNumberFormItem, RadioItem, EmailItem } from "../form";
import { npiInputRule, onlyWhitespacesRule, zipInputRules } from "../../utils";
import DobFormItem from "../DobFormItem";
import { GenderFormItem } from "../../GenderFormItem";
import { insuranceFields, patientFields, practiceFields } from "./fieldNames";
import { FormDividerText } from "../styledComponents";
import { BLUE_3, GRAY_500, LIGHT_BLUE_5 } from "../../../../../constant/colors";
import { useGetLabels } from "../../../../../hooks/useGetLabels";
import { PhysicanItem } from "../form/PhysicianItem";

export const CommonFieldBankController = () => {
  const { getLabelsBySection } = useGetLabels(commonLabels);
  const insuranceLabels = getLabelsBySection(formSections.INSURANCE_SECTION);
  const patientLabels = getLabelsBySection(formSections.PATIENT_SECTION);
  const practiceLabels = getLabelsBySection(formSections.PRACTICE_SECTION);
  return {
    patient: {
      gender: ({
        name = patientFields.gender,
        label = patientLabels.gender,
        showError,
        thirdOption = "None",
        width = "100%",
        radioStyle = { display: "flex" },
        additionalOptions,
        showThirdOption = true,
        widthForAdditionalOptions,
        minWidth,
        minWidthForAdditionalOptions,
        required,
        key = "patient-gender"
      }) => (
        <GenderFormItem
          required={required}
          name={name}
          label={label}
          showError={showError}
          width={width}
          thirdOption={thirdOption}
          radioStyle={radioStyle}
          additionalOptions={additionalOptions}
          showThirdOption={showThirdOption}
          widthForAdditionalOptions={widthForAdditionalOptions}
          minWidth={minWidth}
          minWidthForAdditionalOptions={minWidthForAdditionalOptions}
          key={key}
        />
      ),
      dob: ({ required, name = patientFields.dob, label = patientLabels.dob, disabled, key = "patient-dob" }) => (
        <DobFormItem required={required} name={name} label={label} fieldProps={{ disabled }} key={key} />
      ),
      additional_phone_number: ({
        required,
        name = patientFields.additionalPhone,
        label = patientLabels.additionalPhoneNumber,
        placeholder = patientLabels.additionalPhoneNumberPlaceholder,
        typeName = patientFields.additionalPhoneType,
        key = "patient-additiona-number"
      }) => (
        <PhoneNumberFormItem
          required={required}
          name={name}
          label={label}
          placeholder={placeholder}
          typeName={typeName}
          key={key}
        />
      )
    },
    insurance: {
      isInsured: ({
        required,
        name = insuranceFields.isInsured,
        label = insuranceLabels.isInsured,
        formItemProps,
        key = "insurance-is-insured"
      }) => (
        <RadioItem
          key={key}
          formItemProps={formItemProps}
          name={name}
          label={label}
          rules={[{ required }]}
          optionItems={[
            { value: true, displayValue: "Yes" },
            { value: false, displayValue: "No" }
          ]}
        />
      ),
      payerName: ({
        required,
        name = insuranceFields.primaryPayerName,
        label = insuranceLabels.primaryPayerName,
        placeholder = insuranceLabels.payerNamePlaceholder,
        key = "insurance-payer-name"
      }) => <InputItem name={name} label={label} required={required} placeholder={placeholder} key={key} />,
      informationSection: ({ isInsured, key = "insurance-information-section" }) => (
        <Alert
          style={{ backgroundColor: LIGHT_BLUE_5, borderRadius: 8, marginBottom: 20 }}
          message={
            <Text style={{ color: BLUE_3, whiteSpace: "pre-line" }}>
              {isInsured ? insuranceLabels.insuredAlreadyChosen : insuranceLabels.uninsuredAlreadyChosen}
            </Text>
          }
          type="info"
          showIcon
          icon={<InfoCircleOutlined style={{ alignSelf: "flex-start", paddingTop: 4 }} />}
          key={key}
        />
      )
    },
    practice: {
      prescriberFirstName: ({
        required,
        name = practiceFields.prescriberFirstName,
        label = practiceLabels.prescriberFirstName,
        placeholder = practiceLabels.prescriberFirstNamePlaceholder,
        key = "prescriber-first-name"
      }) => <InputItem name={name} label={label} required={required} placeholder={placeholder} key={key} />,
      prescriberLastName: ({
        required,
        name = practiceFields.prescriberLastName,
        label = practiceLabels.prescriberLastName,
        placeholder = practiceLabels.prescriberLastNamePlaceholder,
        key = "prescriber-last-name"
      }) => <InputItem name={name} label={label} required={required} placeholder={placeholder} key={key} />,
      prescriberSelect: ({
        name = practiceFields.prescriberName,
        label = practiceLabels.prescriberName,
        required,
        dropdownRender,
        onClear,
        onChange,
        isOpen,
        onDropdownVisibleChange,
        placeholder = practiceLabels.prescriberFullNamePlaceholder,
        id = "EE_genentech_free_drug_physician_select",
        key = "prescriber-select"
      }) => (
        <PhysicanItem
          name={name}
          label={label}
          required={required}
          dropdownRender={dropdownRender}
          onClear={onClear}
          onChange={onChange}
          isOpen={isOpen}
          onDropdownVisibleChange={onDropdownVisibleChange}
          placeholder={placeholder}
          id={id}
          key={key}
        />
      )
    },
    common: {
      id: () => <InputItem name={patientFields.id} formItemProps={{ hidden: true }} />,
      firstName: ({
        required,
        name = patientFields.firstName,
        label = patientLabels.firstName,
        placeholder = patientLabels.firstNamePlaceholder,
        fieldProps,
        rules = [],
        key = "patient-first-name"
      }) => (
        <InputItem
          name={name}
          label={label}
          required={required}
          rules={rules}
          placeholder={placeholder}
          fieldProps={fieldProps}
          key={key}
        />
      ),
      lastName: ({
        required,
        name = patientFields.lastName,
        label = patientLabels.lastName,
        placeholder = patientLabels.lastNamePlaceholder,
        fieldProps,
        key = "patient-last-name",
        rules = []
      }) => (
        <InputItem
          name={name}
          label={label}
          required={required}
          rules={rules}
          placeholder={placeholder}
          fieldProps={fieldProps}
          key={key}
        />
      ),
      phone_number: ({
        required,
        name = patientFields.phone,
        label = patientLabels.phoneNumber,
        placeholder = patientLabels.phoneNumberPlaceholder,
        typeName = patientFields.phoneType,
        addon = true,
        key = "patient-phone-number"
      }) => (
        <PhoneNumberFormItem
          required={required}
          name={name}
          label={label}
          placeholder={placeholder}
          typeName={typeName}
          addon={addon}
          key={key}
        />
      ),
      address: ({
        required,
        name = patientFields.address,
        label = patientLabels.address,
        placeholder = patientLabels.addressPlaceholder,
        rules = [],
        key = "patient-address"
      }) => (
        <InputItem name={name} label={label} placeholder={placeholder} required={required} rules={rules} key={key} />
      ),
      city: ({
        required,
        name = patientFields.city,
        label = patientLabels.city,
        placeholder = patientLabels.cityPlaceholder,
        rules = [],
        key = "patient-city"
      }) => (
        <InputItem name={name} label={label} placeholder={placeholder} required={required} rules={rules} key={key} />
      ),
      npi: ({
        required,
        name = practiceFields.prescriberNpi,
        label = practiceLabels.prescriberNpi,
        placeholder = practiceLabels.npiPlaceholder,
        key = "prescriber-npi"
      }) => (
        <InputItem
          name={name}
          label={label}
          required={required}
          rules={[npiInputRule, onlyWhitespacesRule]}
          placeholder={placeholder}
          fieldProps={{ maxLength: 10 }}
          key={key}
        />
      ),
      groupTaxId: ({
        required,
        name = practiceFields.groupTaxId,
        label = practiceLabels.groupTaxId,
        placeholder = practiceLabels.groupTaxIdPlaceholder,
        key = "group-tax-id"
      }) => <InputItem name={name} label={label} required={required} placeholder={placeholder} key={key} />,
      state: ({
        required,
        name = patientFields.state,
        label = patientLabels.state,
        placeholder = patientLabels.statePlaceholder,
        key = "patient-state"
      }) => <StateItem required={required} name={name} label={label} placeholder={placeholder} key={key} />,
      zip_code: ({
        required,
        name = patientFields.zip,
        label = patientLabels.zipCode,
        placeholder = patientLabels.zipCodePlaceholder,
        key = "patient-zip-code"
      }) => (
        <InputItem
          required={required}
          rules={zipInputRules}
          name={name}
          label={label}
          placeholder={placeholder}
          fieldProps={{ maxLength: 5, style: { width: "100%" } }}
          key={key}
        />
      ),
      email: ({
        required,
        name = patientFields.email,
        label = patientLabels.email,
        placeholder = patientLabels.emailPlaceholder,
        fieldProps = {},
        additionalRules = [],
        key = "patient-email"
      }) => (
        <EmailItem
          required={required}
          name={name}
          label={label}
          placeholder={placeholder}
          fieldProps={fieldProps}
          additionalRules={additionalRules}
          key={key}
        />
      ),
      divider: ({ id, text }) => {
        return (
          <Divider id={id} orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
            <FormDividerText>{text}</FormDividerText>
          </Divider>
        );
      }
    }
  };
};
