import ACTIONS from "./action";
import { tableColumns } from "../constant/tableColumns";
import {
  defaultFilters,
  defaultFiltersInputs,
  defaultSavedListFilters,
  defaultSavedListSorting
} from "../constant/filters";
import { defaultPatientState } from "../constant/patient";
import commonReducers from "@tailormed/common-client/store/reducer";
import { selectedViews } from "../constant/table";
import { RequiredSignerTypesBySignerType, ApplicationFormSignatureStatuses } from "../constant/ESign";
const defaultState = {
  idpConfig: undefined,
  oktaClientId: undefined,

  environment: undefined,
  tier: undefined,
  isPhiAllowed: undefined,
  user: undefined,
  users: [],
  settings: {},
  selectedAssistanceProgramById: undefined,
  showAssistanceProgramModal: false,
  showExpressEnrollModal: false,
  showSupport: {
    visible: false,
    assistancePrograms: undefined
  },
  showSecureUpload: {
    visible: false
  },
  currentApplication: undefined,
  assistancePrograms: [],
  assignees: [],
  userGroups: [],
  networkActions: {},
  tableColumns,
  filters: defaultFilters,
  filtersInputs: defaultFiltersInputs,
  searchedPatients: [],
  selectedView: undefined,
  providers: {},
  tablesData: {},
  savedListFilters: {
    query: defaultSavedListFilters.query,
    sortingStatuses: defaultSavedListSorting,
    filterBy: defaultSavedListFilters.filterBy
  },
  formPopups: {
    visible: false,
    contentKey: null
  },
  units: [],
  unknownAndInactiveInsuranceInfo: [],
  visitType: [],
  filterList: {},
  filterListStatuses: {
    id: undefined,
    isModified: false,
    selectedPage: 1,
    sorter: {},
    canModify: false,
    showDiscardModal: false,
    nextSelectedView: ""
  },
  isSavedListinitialized: false,
  drugs: [],
  summaryCounters: {},
  patient: defaultPatientState,
  oopEstimations: [],
  formItemsDiagnoses: [],
  APSponsors: [],
  applicationClaims: {
    claims: []
  },
  filterByClaimsStatus: "",
  isCopyPastePopoverOpen: false,
  isExpressEnrollFormOpen: false,
  enrollment: {
    isUpdatingFile: false,
    applicationFormSignatures: null,
    pdfMetaData: {},
    remoteRequestErrorMessage: {},
    loadingWidgets: {},
    isApplicationFormModalVisible: false
  },
  badgeCounts: { notifications: 0, tasks: 0 },
  isSidebarCollapsed: false,
  shouldReturnToRelevantPatient: { shouldReturn: false, notificationId: null },
  ...commonReducers.defaultState
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.SET_IDP_CONFIG: {
      const { idpConfig } = action;
      return { ...state, idpConfig };
    }
    case ACTIONS.Types.SET_OKTA_CONFIG: {
      const { oktaClientId } = action;
      return { ...state, oktaClientId };
    }
    case ACTIONS.Types.LOGOUT: {
      return { ...defaultState };
    }

    case ACTIONS.Types.SET_ENVIRONMENT: {
      const { environment } = action;
      return { ...state, environment };
    }

    case ACTIONS.Types.SET_TIER: {
      const { tier } = action;
      return { ...state, tier };
    }

    case ACTIONS.Types.SET_USER: {
      const { user } = action;
      return { ...state, user };
    }

    case ACTIONS.Types.SET_USERS: {
      const { users } = action;
      return { ...state, users };
    }

    case ACTIONS.Types.SET_ASSIGNEES: {
      const { assignees } = action;
      return { ...state, assignees };
    }

    case ACTIONS.Types.INIT_SAVED_LIST: {
      return { ...state, isSavedListinitialized: true };
    }

    case ACTIONS.Types.SET_USER_GROUPS: {
      const { userGroups } = action;
      return { ...state, userGroups };
    }

    case ACTIONS.Types.SET_IS_PHI_ALLOWED: {
      const { isPhiAllowed } = action;
      return { ...state, isPhiAllowed };
    }

    case ACTIONS.Types.SET_IS_SIDE_BAR_COLLAPSED: {
      const { isSidebarCollapsed } = action;
      return { ...state, isSidebarCollapsed };
    }

    case ACTIONS.Types.SET_SHOULD_RETURN_TO_RELEVANT_PATIENT: {
      const { shouldReturn, notificationId } = action;
      return { ...state, shouldReturnToRelevantPatient: { shouldReturn, notificationId } };
    }

    case ACTIONS.Types.SET_SELECTED_VIEW: {
      const { selectedView } = action;
      return { ...state, selectedView };
    }

    case ACTIONS.Types.UPDATE_FILTER_LIST_STATUSES: {
      const { filterListID, canModify, showDiscardModal, nextSelectedView } = action;
      return {
        ...state,
        filterListStatuses: {
          ...state.filterListStatuses,
          id: filterListID,
          canModify,
          showDiscardModal,
          nextSelectedView
        }
      };
    }

    case ACTIONS.Types.MARK_SAVED_FILTER_AS_CHANGED: {
      const { filterListID, filterListStatus } = action;
      return {
        ...state,
        filterListStatuses: {
          ...state.filterListStatuses,
          id: filterListID,
          isModified: filterListStatus
        }
      };
    }

    case ACTIONS.Types.UPDATE_SAVED_FILTER_VIEW_PARAMETERS: {
      const { filterListID, selectedPage = 1, sorter = {} } = action;
      return {
        ...state,
        filterListStatuses: {
          ...state.filterListStatuses,
          id: filterListID,
          selectedPage,
          sorter
        }
      };
    }

    case ACTIONS.Types.CLEAR_SORTER: {
      const { view } = action;
      return {
        ...state,
        tablesData: {
          ...state.tablesData,
          [view]: { ...state.tablesData[view], sorter: {} }
        }
      };
    }

    case ACTIONS.Types.CLEAR_SELECTED_VIEW: {
      return { ...state, selectedView: undefined };
    }

    case ACTIONS.Types.SET_OOP_ESTIMATIONS: {
      const { data } = action;
      return { ...state, oopEstimations: data };
    }

    case ACTIONS.Types.SET_FORM_ITEMS_DIAGNOSES: {
      const { data } = action;
      return { ...state, formItemsDiagnoses: data };
    }

    case ACTIONS.Types.SET_ASSISTANCE_PROGRAMS: {
      const { assistancePrograms } = action;
      return { ...state, assistancePrograms };
    }

    case ACTIONS.Types.SET_SHOW_ASSISTANCE_PROGRAM_MODAL: {
      const { showAssistanceProgramModal } = action;
      return { ...state, showAssistanceProgramModal };
    }

    case ACTIONS.Types.SET_SHOW_EXPRESS_ENROLL_MODAL: {
      const { showExpressEnrollModal } = action;
      return { ...state, showExpressEnrollModal };
    }

    case ACTIONS.Types.SET_SHOW_SUPPORT: {
      const { visible, assistanceProgram } = action;
      return { ...state, showSupport: { ...state.showSupport, visible, assistanceProgram } };
    }

    case ACTIONS.Types.SET_SHOW_SECURE_UPLOAD: {
      const { visible } = action;
      return { ...state, showSecureUpload: { ...state.showSecureUpload, visible } };
    }

    case ACTIONS.Types.SET_SELECTED_ASSISTANCE_PROGRAM_BY_ID: {
      const { assistanceProgram } = action;
      return { ...state, selectedAssistanceProgramById: assistanceProgram };
    }

    case ACTIONS.Types.CLEAR_SELECTED_ASSISTANCE_PROGRAM_BY_ID: {
      return { ...state, selectedAssistanceProgramById: undefined };
    }

    case ACTIONS.Types.CLEAR_ASSISTANCE_PROGRAMS: {
      return { ...state, assistancePrograms: [] };
    }

    case ACTIONS.Types.SET_NETWORK_ACTION: {
      const { actionType, isFetching, msg, code } = action;
      return { ...state, networkActions: { ...state.networkActions, [actionType]: { isFetching, msg, code } } };
    }

    case ACTIONS.Types.SET_TABLE_COLUMNS: {
      const { tableColumns } = action;
      return { ...state, tableColumns };
    }

    case ACTIONS.Types.SET_SEARCHED_PATIENTS: {
      const { searchedPatients, selectedPage, sorter } = action;
      return { ...state, searchedPatients: { ...searchedPatients, selectedPage, sorter } };
    }

    case ACTIONS.Types.UPDATE_SEARCHED_PATIENT: {
      const { patientId, sharedFields } = action;
      let searchedPatientIndex = state.searchedPatients?.patients?.findIndex(
        ({ patientId: id }) => parseInt(id) == patientId
      );
      if (searchedPatientIndex >= 0) {
        let searchedPatients = {
          ...state.searchedPatients,
          patients: Object.assign([...state.searchedPatients.patients], {
            [searchedPatientIndex]: { ...state.searchedPatients.patients[searchedPatientIndex], ...sharedFields }
          })
        };
        return { ...state, searchedPatients };
      }
      return state;
    }

    case ACTIONS.Types.CLEAR_SEARCHED_PATIENTS: {
      return { ...state, searchedPatients: [] };
    }

    case ACTIONS.Types.SET_FILTERS: {
      const { filters } = action;
      return { ...state, filters };
    }

    case ACTIONS.Types.CLEAR_FILTERS: {
      return { ...state, filters: defaultFilters };
    }

    case ACTIONS.Types.SET_FILTERS_INPUTS: {
      const { filterName, value } = action;
      return { ...state, filtersInputs: { ...state.filtersInputs, [filterName]: value } };
    }

    case ACTIONS.Types.SET_MULTIPLE_FILTERS_INPUTS: {
      const { filters } = action;
      return { ...state, filtersInputs: { ...defaultFiltersInputs, ...filters } };
    }

    case ACTIONS.Types.CLEAR_FILTERS_INPUTS: {
      return { ...state, filtersInputs: defaultFiltersInputs };
    }

    case ACTIONS.Types.RESET_SAVED_LIST_VIEW_FILTERS: {
      return {
        ...state,
        savedListFilters: { ...defaultSavedListFilters, sortingStatuses: defaultSavedListSorting }
      };
    }

    case ACTIONS.Types.SET_TABLES_DATA: {
      const { tableData, selectedPage, selectedView, sorter } = action;

      return {
        ...state,
        tablesData: {
          ...state.tablesData,
          [selectedView]: { ...tableData, selectedPage: selectedPage, sorter }
        }
      };
    }

    case ACTIONS.Types.SET_PATIENT_TABLE_RECORD_DATA: {
      const { patientId, sharedFields } = action;
      const newTableData = {};
      Object.entries({ ...state.tablesData }).forEach(([tableKey, { patients, ...rest }]) => {
        newTableData[tableKey] = {
          ...rest,
          patients: patients
            ? patients.map((record) =>
                record.patientId === patientId ? { ...record, ...sharedFields } : { ...record }
              )
            : undefined
        };
      });
      return {
        ...state,
        tablesData: newTableData
      };
    }

    case ACTIONS.Types.SET_SAVED_LISTS_TABLE_RECORD_DATA: {
      const { recordData } = action;
      const savedListKey = selectedViews.savedList;

      const savedListRecords = [...state.tablesData[savedListKey].data];
      const listIndex = savedListRecords.findIndex((record) => record.id === recordData.id);
      savedListRecords[listIndex] = recordData;

      return {
        ...state,
        tablesData: {
          ...state.tablesData,
          [savedListKey]: { ...state.tablesData[savedListKey], data: savedListRecords }
        }
      };
    }
    case ACTIONS.Types.SET_SETTINGS: {
      const { settings } = action;
      const settingsObject = {};
      settings.map((setting) => (settingsObject[setting.key] = setting));
      return { ...state, settings: settingsObject };
    }

    case ACTIONS.Types.SET_SELECTED_FILTER_LIST_TYPE: {
      const { listType } = action;
      return {
        ...state,
        savedListFilters: {
          ...state.savedListFilters,
          filterBy: listType
        }
      };
    }

    case ACTIONS.Types.SET_LIST_NAME_QUERY_ACTION: {
      const { query } = action;
      return {
        ...state,
        savedListFilters: {
          ...state.savedListFilters,
          query
        }
      };
    }

    case ACTIONS.Types.SET_SAVED_LIST_SORT_BY_ACTION: {
      const { sortBy } = action;
      const { filterBy, sortingStatuses } = state.savedListFilters;
      const tabColumns = sortingStatuses[filterBy];
      return {
        ...state,
        savedListFilters: {
          ...state.savedListFilters,
          sortingStatuses: {
            ...sortingStatuses,
            [filterBy]: { ...tabColumns, [tabColumns.orderBy]: sortBy }
          }
        }
      };
    }

    case ACTIONS.Types.SET_SAVED_LIST_ORDER_BY_ACTION: {
      const { orderBy } = action;
      const { filterBy, sortingStatuses } = state.savedListFilters;
      const tabColumns = sortingStatuses[filterBy];

      return {
        ...state,
        savedListFilters: {
          ...state.savedListFilters,
          sortingStatuses: {
            ...sortingStatuses,
            [filterBy]: { ...tabColumns, orderBy }
          }
        }
      };
    }

    case ACTIONS.Types.PATIENT_DETAILS: {
      const { patientData } = action;
      return {
        ...state,
        patient: {
          ...state.patient,
          details: patientData
        }
      };
    }

    case ACTIONS.Types.CLEAR_PATIENT: {
      return { ...state, patient: defaultPatientState };
    }

    case ACTIONS.Types.SET_PATIENT_FOUND: {
      const { isFound } = action;
      return { ...state, patient: { ...state.patient, isFound } };
    }

    case ACTIONS.Types.PROVIDERS: {
      const { providerType, providerData } = action;
      return { ...state, providers: { ...state.providers, [providerType]: providerData } };
    }

    case ACTIONS.Types.GET_AP_SPONSORS: {
      const { data } = action;
      return { ...state, APSponsors: data };
    }

    case ACTIONS.Types.POTENTIAL_SAVINGS: {
      const { potentialSavings } = action;
      return { ...state, patient: { ...state.patient, potentialSavings: potentialSavings } };
    }

    case ACTIONS.Types.SET_CURRENT_APPLICATION: {
      const { currentApplication } = action;
      return { ...state, currentApplication };
    }

    case ACTIONS.Types.SET_COPY_PASTE_POPOVER: {
      const { isCopyPastePopoverOpen } = action;
      return { ...state, isCopyPastePopoverOpen };
    }

    case ACTIONS.Types.SET_EXPRESS_ENROLL_FORM_OPEN: {
      const { isExpressEnrollFormOpen } = action;
      return { ...state, isExpressEnrollFormOpen };
    }

    case ACTIONS.Types.SET_UNITS: {
      const { units } = action;
      return { ...state, units: units };
    }

    case ACTIONS.Types.SET_FILTER_LIST: {
      const { filterList } = action;
      return { ...state, filterList: filterList };
    }

    case ACTIONS.Types.SET_PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE: {
      const { unknownAndInactiveInsuranceInfo } = action;
      return { ...state, unknownAndInactiveInsuranceInfo: unknownAndInactiveInsuranceInfo };
    }

    case ACTIONS.Types.SET_VISITS_TYPES: {
      const { visitType } = action;
      return { ...state, visitType: visitType };
    }

    case ACTIONS.Types.SET_FORM_POPUPS: {
      const { visible, contentKey } = action;
      return { ...state, formPopups: { visible, contentKey } };
    }

    case ACTIONS.Types.SET_APPLICATION_CLAIMS: {
      const { claims } = action;
      return { ...state, applicationClaims: { claims } };
    }

    case ACTIONS.Types.SET_FILTER_BY_CLAIMS_STATUS: {
      const { filterByClaimsStatus } = action;
      return { ...state, filterByClaimsStatus };
    }

    case ACTIONS.Types.CLEAR_APPLICATION_CLAIMS: {
      return {
        ...state,
        applicationClaims: defaultState.applicationClaims,
        filterByClaimsStatus: defaultState.filterByClaimsStatus
      };
    }

    case ACTIONS.Types.SET_SUMMARY_COUNTERS: {
      const { summaryCounters } = action;
      return { ...state, summaryCounters: summaryCounters };
    }

    case ACTIONS.Types.PATIENT_JOURNEY: {
      const { journeyData } = action;
      return { ...state, patient: { ...state.patient, journey: journeyData } };
    }

    case ACTIONS.Types.GENERIC_SERVICES: {
      const { genericServices } = action;
      return {
        ...state,
        patient: {
          ...state.patient,
          genericServices: genericServices
        }
      };
    }

    case ACTIONS.Types.PATIENT_OPPORTUNITY: {
      const { data } = action;
      return { ...state, patient: { ...state.patient, opportunity: data } };
    }

    case ACTIONS.Types.POTENTIAL_SAVINGS_JOURNEY: {
      const { data } = action;
      return { ...state, patient: { ...state.patient, potentialSavingsJourney: data } };
    }

    case ACTIONS.Types.SET_SELECTED_JOURNEY_EVENT_HANDLER: {
      const { selectedJourneyEvent } = action;
      return { ...state, patient: { ...state.patient, selectedJourneyEvent: selectedJourneyEvent } };
    }

    case ACTIONS.Types.PATIENT_COVERAGES: {
      const { coverages } = action;
      return { ...state, patient: { ...state.patient, coverages } };
    }

    case ACTIONS.Types.POLICIES: {
      const { policies } = action;
      return { ...state, patient: { ...state.patient, policies } };
    }

    case ACTIONS.Types.PATIENT_SHIPPING_OPTIONS: {
      const { shippingOptions } = action;
      return { ...state, patient: { ...state.patient, shippingOptions: shippingOptions } };
    }

    case ACTIONS.Types.POTENTIAL_SAVING: {
      const { papData } = action;
      const updatePotentialSavings = state.patient.potentialSavings.map((p) => {
        if (p.id === papData.id) {
          return papData;
        } else {
          return p;
        }
      });
      return {
        ...state,
        patient: {
          ...state.patient,
          potentialSavings: updatePotentialSavings
        }
      };
    }

    case ACTIONS.Types.UPDATE_APPLICATION_RELEVANCE: {
      const { papId, isIrrelevantForRenewal } = action;
      const indexOfRequestedPs = state.patient.potentialSavings.findIndex(
        (potentialSaving) => potentialSaving.id === papId
      );
      const updatedPotentialSaving = [...state.patient.potentialSavings];
      updatedPotentialSaving[indexOfRequestedPs].isIrrelevantForRenewal = isIrrelevantForRenewal;

      return { ...state, patient: { ...state.patient, potentialSavings: updatedPotentialSaving } };
    }

    case ACTIONS.Types.SET_APPLICATION_FORM: {
      const { applicationForm } = action;
      return { ...state, patient: { ...state.patient, applicationForm } };
    }

    case ACTIONS.Types.SET_EXPRESS_ENROLLMENT_CONFIGURATION: {
      const { expressEnrollmentConfiguration } = action;
      return { ...state, patient: { ...state.patient, expressEnrollmentConfiguration } };
    }

    case ACTIONS.Types.SET_APPLICATION_ATTACHMENTS: {
      const { applicationAttachments } = action;
      return { ...state, patient: { ...state.patient, applicationAttachments } };
    }

    case ACTIONS.Types.UPDATE_ENROLLMENT_STORE: {
      const { key, value } = action;
      return { ...state, enrollment: { ...state.enrollment, [key]: value } };
    }

    case ACTIONS.Types.RESET_ENROLLMENT_STORE: {
      return { ...state, enrollment: { ...defaultState.enrollment } };
    }

    case ACTIONS.Types.SET_BADGE_COUNTS: {
      const { key, value } = action;
      return { ...state, badgeCounts: { ...state.badgeCounts, [key]: value } };
    }

    case ACTIONS.Types.RESET_APPLICATION_FORM_SIGNATURE: {
      const { signerType } = action;
      const currentApplicationFormSignatures = state.enrollment.applicationFormSignatures;

      const notUpdatedSignatures = currentApplicationFormSignatures.filter(
        (signature) => signature.signerType !== signerType
      );

      let currSigToUpdate = currentApplicationFormSignatures.find((signature) => signature.signerType === signerType);

      currSigToUpdate = {
        ...currSigToUpdate,
        status: ApplicationFormSignatureStatuses.NOT_STARTED,
        type: null,
        signedBy: null,
        signerType: null,
        phiRemoteSignatureRequestId: null,
        phiRemoteSignatureRequest: null
      };
      const updatedSignatures = [...notUpdatedSignatures, currSigToUpdate];
      return { ...state, enrollment: { ...state.enrollment, applicationFormSignatures: updatedSignatures } };
    }

    case ACTIONS.Types.SET_APPLICATION_FORM_SIGNATURE: {
      const { signerType, applicationFormSignature } = action;
      const currentApplicationFormSignatures = state.enrollment.applicationFormSignatures;
      let relevantRequiredSignerTypes = RequiredSignerTypesBySignerType[signerType] || [];

      const notUpdatedSignatures = currentApplicationFormSignatures.filter(
        (signature) => !relevantRequiredSignerTypes.includes(signature.requiredSignerType)
      );

      const updatedSignatures = [...notUpdatedSignatures, applicationFormSignature];

      return { ...state, enrollment: { ...state.enrollment, applicationFormSignatures: updatedSignatures } };
    }

    case ACTIONS.Types.SET_APPLICATION_FORM_MODAL_VISIBILITY: {
      const { isVisible } = action;
      return { ...state, enrollment: { ...state.enrollment, isApplicationFormModalVisible: isVisible } };
    }

    default: {
      return commonReducers.reducer(state, action);
    }
  }
};

export default reducer;
