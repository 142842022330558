import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteDiagnosisPopupIcon } from "../../../assets/svg/delete-new-icon.svg";
import { ErrorButton, OutlinedButton } from "../../customComponent/Button";
import { PopupStyledModal } from "../../customComponentNewDesign/CustomPopupModal";
import { font18pxSB } from "../../Typography";
import styled from "styled-components";

const DialogTitle = styled.p`
  ${font18pxSB}
`;

const DiscardChangesPopup = ({
  visible = true,
  onClickDiscard = () => {},
  onClickCancel = () => {},
  popupTitle,
  popupDescription
}) => {
  const { t } = useTranslation();
  return (
    <PopupStyledModal
      title={<DeleteDiagnosisPopupIcon />}
      open={visible}
      closable={false}
      footer={[
        <OutlinedButton key="back" onClick={onClickCancel} id="discard_changes_cancel">
          {t("back")}
        </OutlinedButton>,
        <ErrorButton key="ok" onClick={onClickDiscard} id="discard_changes_ok">
          {t("approve_discard")}
        </ErrorButton>
      ]}
    >
      <DialogTitle>{popupTitle}</DialogTitle>
      {popupDescription ? popupDescription : t("cancel_modal_description")}
    </PopupStyledModal>
  );
};

export default DiscardChangesPopup;
