import React from "react";
import moment from "moment";

import getEgalePemfexySidebarItems from "./sidebarItems";
import EaglePemfexyPatient from "./form/formSections/patient";
import { CompleteFormButton } from "../CompleteFormButton";
import { getEgalePemfexyFormInitialValues } from "./form/initialValues";
import * as egalePemfexyFieldNames from "./form/fieldNames";
import EaglePemfexyCopayInsurance from "./form/formSections/Insurance";
import EaglePemfexyCopayProvider from "./form/formSections/Provider";
import Practice from "./form/formSections/Practice";
import EaglePemfexyAttachments from "./form/formSections/attachments";
import Clinical from "./form/formSections/Clinical";
import { SubmittedFormModal } from "../../PatientApplications/enrollTab/submission/SubmittedFormModal";
import { SaveButton } from "../header";
import { applyTrimFields } from "../utils/updateFormData";
import { DATE_FORMAT } from "../../../../utils/date";
import { Trans } from "react-i18next";
import { SignedFormUploader } from "../../PatientApplications/enrollTab/utils/uploadFunctionality";
import { fileTypes } from "../../../Uploader";
import { EEApproveApplicationMockAPI } from "../../../../api/api";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../constants";

const eaglePemfexyConfiguration = {
  getSidebarItems: getEgalePemfexySidebarItems,
  activeFormSection: "#enrollment-form-patient-information-anchor",
  formId: "eagle_smart_editor",
  fieldNames: egalePemfexyFieldNames,
  getFormInitialValues: getEgalePemfexyFormInitialValues,
  getFormSectionComponents: () => (
    <>
      <EaglePemfexyPatient />
      <EaglePemfexyCopayInsurance />
      <EaglePemfexyCopayProvider />
      <Practice />
      <Clinical />
      <EaglePemfexyAttachments />
    </>
  ),
  getViewDetailsModal: (onCancel, visible) => <SubmittedFormModal onCancel={onCancel} visible={visible} />,
  applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_complete_button"} {...props} />,
  headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM,
  successMessage: (t, submittedDate) =>
    t("patientApplications.enroll.application_form_submission.submit_completed", {
      submitDate: moment(submittedDate).format(DATE_FORMAT)
    }),
  inProgressMessage: (t, submittedDate) => (
    <Trans
      i18nKey="patientApplications.enroll.application_form_submission.eagle_pemfexy.submit_in_progress"
      components={{
        bold: <strong />
      }}
      values={{ submitDate: moment(submittedDate).format(DATE_FORMAT) }}
    />
  ),
  saveButton: (props) => (
    <SaveButton onSave={(form) => applyTrimFields(form)} id={"EE_eagleـpemfexy_copay_save_pdf"} {...props} />
  ),
  signatureStep: {
    uploadButtonText: (t) => t("patientApplications.enroll.electronic_signatures.upload_signed_form_button"),
    ESignButtonText: (t) => t("patientApplications.enroll.electronic_signatures.get_signatures_button"),
    getDocusignRecipients: (t) => [
      { type: "patient", role: t("patientApplications.enroll.electronic_signatures.patient_recipient_one") },
      { type: "provider", role: t("patientApplications.enroll.electronic_signatures.physician_recipient_two") },
      { type: "sender", role: t("patientApplications.enroll.electronic_signatures.sender") }
    ],
    fileTypesAccepted: fileTypes.PDF,
    uploadAttachmentButton: SignedFormUploader,
    pendoUploadSignAttachment: "Upload Signed PDF",
    pendoUploadSignAttachmentActionTaken: "Signed PDF has been attached to application"
  },
  submissionStep: {
    description: (t) => t("patientApplications.enroll.application_form_submission.eagle_pemfexy.description"),
    getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.eagle_pemfexy.submit_button"),
    submitModal: {
      showNotice: false,
      attachments: {
        showAllowedFileTypesNotice: false
      }
    },
    getSubmitSuccessMessage: (t, patientId) =>
      t("patientApplications.enroll.application_form_submission.eagle_pemfexy.submit_success_message", {
        patientId
      }),
    afterSubmitCompleted: async (applicationForm) => {
      if (applicationForm?.isDemo) {
        return EEApproveApplicationMockAPI(applicationForm.id);
      }
    }
  },
  afterSubmissionPayload: () => ({}),
  shouldSaveAppear: () => true,
  getShowEnrollResult: () => false,
  prepareButtonId: "EE_eagleـpemfexy_copay_prepare_application_form",
  viewDetailsButtonId: "EE_eagleـpemfexy_copay_view_submission_details",
  applicationSupportsEEId: "application_supports_eagleـpemfexy_copay_EE"
};

export default eaglePemfexyConfiguration;
