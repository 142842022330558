import _ from "lodash";

import { PhoneNumberType } from "../../../../../constants";
import { calculateAge } from "../../../../../utils";

export const getPfizerCopayOralIQVIAFormInitialValues = ({ applicationForm }) => {
  const formData = applicationForm?.formData ?? {};
  const { patient } = formData;
  const isPatientUnder18 = calculateAge(patient?.dob) < 18;

  const patientState = patient?.state === "" ? null : patient?.state;
  const hasCaregiver = isPatientUnder18 || !_.isEmpty(patient?.caregiver);

  const initialValues = {
    ...formData,
    patient: {
      ...patient,
      state: patientState,
      phoneType: patient?.phoneType || PhoneNumberType.MOBILE,
      hasCaregiver: hasCaregiver || undefined,
      caregiver: {
        ...patient?.caregiver,
        phoneType: patient?.caregiver?.phoneType || PhoneNumberType.MOBILE
      }
    }
  };

  return initialValues;
};
