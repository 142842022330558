import ApplicationFormEditor from "./en/ApplicationFormEditor";

export const en = {
  translation: {
    close: "Close",
    download: "Download",
    cancel_modal_description: "Are you sure you want to discard the changes you made?",
    popup_modal_description_prompt_message: "Are you sure you want to leave? All changes will be lost.",
    popup_modal_description_prompt_message_duplication:
      "Are you sure you want to leave? Duplicated claim will not be saved.",
    approve_discard: "Yes, Discard",
    required: "Required",
    required_message: "This is required",
    dashboard: "Home",
    search_results: "Search Results",
    type_to_search: "Type to search",
    searching: "Searching...",
    loading: "Loading...",
    financial_navigation: "Financial Navigation",
    sign_out: "Sign Out",
    send: "Send",
    patients: "Patients",
    applications: "Applications",
    enrollments: "Enrollments",
    cancel: "Cancel",
    upload: "Upload",
    duplicate: "Duplicate",
    $0: "$ 0",
    save_changes: "Save changes",
    welcome: "Welcome",
    back: "Back",
    select_all: "Select All",
    environment_header: "This is a {{environment}} environment",
    sortInfo: "Sort is only available for {{threshold}}K results or less",
    footer: "© {{year}} TailorMed. All rights reserved",
    main_title: "Good {{timeOfDay}}, {{firstName}}",
    main_subtitle: "Use advanced search and proactive worklists to remove financial barriers for your patients",
    not_found: "Not found",
    alerts: "Alerts",
    login_email_support: "E-mail support",
    login_support: "For support, contact support@tailormed.co",
    support_email: "support@tailormed.co",
    email_to_support: "mailto:support@tailormed.co",
    uploader_secure_upload: "Secure Upload",
    additional_attachments: "Additional Attachments",
    uploader_form_title: "Click the browse button and select file to upload",
    uploader_form_browse: "Browse",
    uploader_form_file_size: "15MB per file",
    uploader_form_browse_more: "Add Another File",
    uploader_form_upload: "Upload",
    uploader_form_cancel: "Cancel",
    uploader_form_back: "Go Back",
    are_you_sure_you_want_to_proceed: " Are you sure you want to proceed?",
    all: "All",
    all_times: "All Times",
    this_week: "This Week",
    this_month: "This Month",
    last_3_days: "Last 3 Days",
    last_week: "Last Week",
    last_month: "Last Month",
    last_2_months: "Last 2 Months",
    last_3_months: "Last 3 Months",
    next_month: "Next Month",
    next_3_days: "Next 3 Days",
    next_7_days: "Next 7 Days",
    next_30_days: "Next 30 Days",
    last_90_days: "Last 90 Days",
    next_2_days: "Next 2 Days",
    indefinitely: "Indefinitely",
    ok: "Ok",
    following_week: "Following Week",
    filter_by: "Filter By",
    today: "Today",
    star: "Star",
    unstar: "Unstar",
    edit: "Edit",
    update: "Update",
    starred: "Starred",
    express_enroll_eligibile: "Express Enroll Eligible",
    hide_deceased_patients: "Hide Deceased Patients",
    awaiting_assistance: "Awaiting Assistance",
    done: "Done",
    follow_up: "Follow-Up",
    in_progress: "In Progress",
    archived: "Archived",
    oop_met: "OOP Met",
    save_as_new_list: "Save As New List",
    analysis_request: "Analysis Request",
    established: "Established",
    new: "New",
    individual: "Individual",
    large_employer: "Large Employer",
    medicare_advantage: "Medicare Advantage",
    medicare_part_d: "Medicare Part D",
    medigap: "Medigap",
    original_medicare: "Original Medicare",
    commercial: "Commercial",
    medicaid: "Medicaid",
    federal: "Federal",
    small_group: "Small Group",
    state: "State",
    state_expansion: "State Expansion",
    uninsured: "Uninsured",
    iv: "IV",
    non_iv: "Non-IV",
    cancer: "Cancer",
    non_cancer: "Non-Cancer",
    active_treatment: "Active Treatment",
    popover_visit_schedule: "Patient’s visits in the selected timeframe:",
    active_treatment_tooltip:
      'Patients are considered in "active treatment" if they have a drug event in the last 6 months',
    express_enroll_eligibile_tooltip:
      "Patients matched to a supported Express Enroll program, without any existing applications.",
    group_patients_visits: "Group Patients’ Visits",
    group_patients_visits_tooltip:
      "Groups all of the patient’s visits for the displayed time frame. Visits are shown according to the first visit date and time",
    filter_selected_plan_markets: "Only selected plan market(s)",
    filter_selected_plan_markets_tooltip:
      "Filters the list to show only patients whose plan market(s) match the selected options. Patients with other plan markets will be excluded.",
    foundation_copay_card: "Foundation Copay Card",
    free_drug: "Free Drug",
    dosage_amount: "Dosage Amount",
    government_plan: "Government Plan",
    copay_foundation: "Copay Foundation",
    living_and_transport_expenses: "Living and Transport Expenses",
    manufacturere_copay_card: "Manufacturer Copay Card",
    internal: "Internal",
    applied: "Applied",
    applied_to_waitlist: "Applied to Waitlist",
    approved: "Approved",
    canceled: "Canceled",
    discarded: "Discarded",
    ineligible: "Ineligible",
    not_interested: "Not Interested",
    not_returned: "Not Returned",
    prepare: "Prepare",
    rejected: "Rejected",
    expired: "Expired",
    exhausted: "Exhausted",
    denied: "Denied",
    clear_all: "Clear All",
    clear: "Clear",
    manage_patient_details: "Manage Patient Details",
    dob: "DOB",
    phone_number: "Phone Number",
    clear_date: "Clear date",
    financial: "Financial",
    total_income_yearly: "Total Income Yearly",
    total_household_size: "Total Household Size",
    assignees: "Assignees",
    unassigned: "Unassigned",
    no_assignees: "No assignees",
    no_physician: "No physician",
    no_facility: "No facility",
    clear_assignees: "Clear assignees",
    facility: "Facility",
    select_facility: "Select facility",
    select_date: "Select date",
    no_oop_limit: "No out-of-pocket limit",
    non_covered_events: "Non covered events",
    inactive_coverage: "Inactive coverage",
    in_network: "In network",
    oop_limit: "Out-of-pocket limit",
    other: "Other",
    deceased: "Deceased",
    select: "Select",
    invalid_fields_should_be_corrected: "Invalid fields should be corrected",
    edit_patient_details: "Edit patient details",
    view_conflicts: "View Conflicts",
    input_required: "This is required.",
    no_patients_found: "No Patients Found!",
    no_enrollments_found: "No Enrollments Found!",
    no_saved_lists_found: "No lists found",
    no_saved_lists_to_show: "No Saved Lists to Show",
    no_favorite_to_show: "No Favorite lists found.",
    no_saved_lists_to_show_description:
      "There are no saved lists in your account yet. Select any patient list to create a new one.",
    no_favorite_description: "You don't have any favorite lists yet. Click the star icon of any list to see it here.",
    submission_processing_title: "Submission is Processing...",
    submission_processing_description: "Please wait while we send the application to the manufacturer",
    invalid_date: "Invalid date",
    sidebar_menu: {
      home: "Home",
      alerts: "Alerts",
      tasks: "Tasks",
      notifications: "Notifications",
      saved_lists: "Saved Lists",
      patients: "Patients",
      all_patients: "All Patients",
      visit_schedule: "Visit Schedule",
      high_priority: "High Priority",
      enrollments: "Enrollments",
      all_enrollments: "All Enrollments",
      pending_renewals: "Pending Renewals",
      claims: "Claims",
      all_claims: "All Claims",
      help_center: "Help Center",
      contact_support: "Contact Support"
    },
    view_editor: "View Editor",
    form_errors: {
      invalid_email: "Invalid Email"
    },
    saved_lists_columns: {
      list_name: "List Name",
      last_modified: "Last Modified"
    },
    saved_list_messages: {
      delete_success: "Saved list deleted successfully",
      delete_error: "Could not delete saved list",
      search_error: "Could not search saved list",
      filter_error: "Could not filter saved list"
    },
    current: "Current",
    imported: "Imported",
    conflicts: "Conflicts",
    coverage_conflicts: {
      title: "Coverages Conflict",
      sub_title:
        "You can choose the correct policy. After you finish and update the policies, you can always reorder them.",
      no_issues_to_resolve: "Seems there's no unsolved conflicts",
      required_message: "Please select all fields",
      no_conflicts_to_resolve: "No Conflicts to resolve"
    },
    under_construction: {
      system_under_construction: "System under construction",
      diagnosis_under_construction: `We're improving how you can manage your patients' details.\nTo add/edit diagnoses for a patient, go to Manage Patient Details > Medical`
    },
    irrelevant: "Irrelevant",
    relevant: "Relevant",
    yes_sure: "Yes, I’m Sure",
    yes_delete: "Yes, Delete",
    main_blocks: {
      saved_list: "Saved Lists",
      all_patients: "All Patients",
      high_priority: "High Priority",
      visit_schedule: "Visit Schedule",
      pending_renewal: "Pending Renewals",
      all_applications: "All Applications",
      all_claims: "All Claims"
    },
    physician_popover: {
      part_one: "The Physician filter is showing patients according to their",
      assigned: "assigned",
      part_two: "physician. When used in the",
      visit_schedule: "Visit Schedule",
      part_three: "list - it will also include all patients with a planned visit for the selected physician/s."
    },
    yes: "Yes",
    no: "No",
    unknown: "Unknown",
    patient: "Patient",
    saved_list_pagination: {
      saved_list_page_count: "Showing: {{range_start}}-{{range_end}} Of {{total}} Lists",
      next: "Next"
    },
    all_claims_pagination: {
      all_claims_page_count: "{{range_start}}-{{range_end}} of {{total}} {{title}}",
      next: "Next",
      title: "Claims"
    },
    patients_pagination: {
      patients_page_count: "Showing: {{range_start}}-{{range_end}} of {{total}} {{title}}",
      many_patients: "many (more than {{max_count_treshold}})"
    },
    tableFilters: {
      patient: "Patient",
      insurance: "Insurance",
      visit_schedule: "Visit Schedule",
      visit_type: "Visit Type",
      high_priority: "High Priority",
      drug: "Drug",
      diagnosis: "Diagnosis",
      potential_savings: "Potential Savings",
      applications: "Applications",
      enrollments: "Enrollments",
      application_drug_name: "Drug Name",
      claim_drug_name: "Claim Drug Name",
      claim_location: "Claim Facility",
      maximum_available_funds: "Maximum Available Funds",
      application_valid_through_date: "Application Valid Through",
      age: "Age",
      status: "Status",
      import_status: "Import Status",
      assignee: "Assignee",
      patientFacility: "Patient Facility",
      visitFacility: "Visit Facility",
      physician: "Physician",
      visit_date: "Visit Date",
      follow_up_date: "Follow-up Date",
      min_patient_balance: "Min. Patient Balance",
      type: "Type",
      plan_market: "Plan Market",
      payer_name: "Payer Name",
      created_date: "Created Date",
      started_date: "Started Date",
      valid_through_date: "Valid Through Date",
      signature_status: "Signature Status",
      approved_date: "Approved Date",
      max_available_funds: "Maximum Available Funds",
      min_remaining_oop: "Min. Remaining OOP",
      route: "Route",
      changed_date: "Changed date",
      name: "Name",
      code: "Code",
      diagnosis_name: "Diagnosis Name",
      diagnosis_code: "Diagnosis Code",
      diagnosis_date: "Diagnosis Date",
      diagnosis_type: "Diagnosis Type",
      drug_route: "Drug Route",
      drug_type: "Drug Type",
      application_status: "Application Status",
      enrollment_status: "Enrollment Status",
      assistance_program_status: "Assistance Program Status",
      ap_sponsor: "Assistance Program Sponsor",
      associated_drugs: "Associated Drug",
      claims: "Claims",
      claim_status: "Claim Status",
      claim_date: "Claim Date",
      claim_follow_up_date: "Follow-up Date",
      claim_submitted_date: "Submitted Date",
      date_of_service: "Date of Service",
      claim_received_date: "Posted Date",
      application_type: "Application Type",
      claim_exists: {
        text: "Claim Exists",
        pop_up_content:
          "Filter by application status: approved, expired, and/or exhausted and application type: copay assistance, when utilizing this filter."
      },
      exists: "Yes",
      doesnt_exists: "No"
    },
    add_new_diagnosis: {
      title: "Add New Diagnosis",
      icd_label: "Diagnosis Name",
      cancel: "Cancel",
      add_diagnosis: "Add Diagnosis",
      icd_required_message: "Please choose diagnosis",
      icd_already_exists_message: "This Patient already has the diagnosis.",
      no_results_found: "No Results Found",
      icd_placeholder: "Enter diagnosis name or ICD code",
      active_date_placeholder: "Active Date",
      diagnosis_status_placeholder: "Diagnosis Status",
      primary_diagnosis_text: "This is a primary diagnosis"
    },
    columns: {
      sortById: "Click to sort descending/ascending by ID",
      record_mrn: "MRN-{{mrnRecord}}",
      record_phone: "Phone: {{mrnRecord}}",
      click_to_sort_ascending: "Click to sort ascending",
      click_to_sort_descending: "Click to sort descending",
      click_to_cancel_sorting: "Click to cancel sorting"
    },
    form_popups: {
      confirmed_close_title: "Unsaved Form Details",
      confirmed_close_content:
        "You have unsaved details in your form. Leaving the form will discard any unsaved changes.",
      add: "Add"
    },
    new_list_form: {
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      save_as_new_list: "Save As New List",
      edit_list_info: "Edit List Info",
      unsaved_details: "Unsaved Details",
      not_saved_yet: "You haven’t saved yet. Leaving the form will discard any unsaved changes.",
      list_name: {
        placeholder: "Choose a name to identify this list",
        title: "List Name"
      },
      description: {
        placeholder: "Enter a description...",
        title: "Description (Optional)"
      },
      list_type: {
        placeholder: "Enter a description...",
        title: "List Type",
        private: {
          title: "Private",
          description: "Only you can view and edit this list."
        },
        public: {
          title: "Public",
          description: "This list will be viewable by all users. Only you can edit."
        }
      }
    },

    placeholders: {
      personal: {
        enter_alternate_contact_full_name: "Enter Alternate Contact Full Name",
        enter_first_name: "Enter First Name",
        enter_last_name: "Enter Last Name",
        enter_middle_name: "Enter Middle Name",
        enter_preferred_name: "Enter Preferred Name",
        enter_street_address: "Enter Street Address",
        enter_city: "Enter City",
        zip: "000000",
        relation_to_patient: "Enter Relation to Patient"
      }
    },

    assistance_program_form: {
      menu_title: "Add Assistance Program",
      title: "Add Patient Assistance Program",
      select_program: "Select Program",
      program_type: "Program Type",
      drug_name: "Drug Name",
      select_drug: "Select Drug",
      drug_name_placeholder: "Drug name",
      insurance_type: "Insurance Type",
      diagnosis_name: "Diagnosis Name",
      diagnosis_code: "Diagnosis ICD Code",
      filters: {
        copay_foundation: "Copay Foundation",
        copay_card: "Copay-Card",
        free_drug: "Free/Replacement Drug Program",
        government_plan: "Government Plans",
        living_expenses: "Living Expenses",
        commercial: "Commercial",
        federal: "Federal",
        medicaid: "Medicaid",
        medicare: "Medicare",
        uninsured: "Uninsured"
      },
      add_drug_form: {
        coverage_info: {
          info: "COVERAGE INFO",
          covered_by_payer: "Drug not covered by payer"
        },
        drug_name: {
          placeholder: "Drug Name",
          required_message: "Drug name is required",
          search: "Type to search"
        },
        dosage: {
          label: "Dosage",
          placeholder: "Drug dosage",
          required_message: "Dosage is required",
          tooltip_text:
            "Dosage field accepts numbers, decimal point and dashes. For drugs which have more than one active ingredient, please enter each ingredient's dose separated by a dash. For example, 24-26 or 1.5-2.5",
          error_validation: "Must be a valid number. May contain a '-' separator"
        },
        dosage_unit: {
          label: "Unit",
          placeholder: "Dosage unit",
          required_message: "Dosage unit is required"
        },
        units_per_day: {
          placeholder: "Units per day"
        },
        number_of_refills: {
          placeholder: "Number of Refills"
        },
        refill_frequency: {
          placeholder: "Refill Frequency (in days)"
        },
        date_picker: {
          placeholder: "Start date",
          required_message: "Start date is required"
        },
        administration_site: {
          placeholder: "Administration site",
          required_message: "Administration site is required"
        }
      },
      drug_not_associated:
        "The selected drug isn't on the patient's profile. To continue, click 'Add' below to create the program and medication automatically, or provide optional medication details below before adding.",
      drug_not_covered: "According to our records, this drug may not be covered by this program.",
      drug_not_associated_description: "Add missing patient drugs:",
      drug_administration_method: "Drug administration method:",
      fields_error: "Please complete all fields.",
      select_drug_error: "Please select drug.",
      failed_add_drug: "Failed to add drug",
      failed_add_assistance_program: "Failed to add assistance program"
    },
    //[DRUGS-TODO-AMIT] remove duplicated translate
    drugs: {
      menu_title: "Add Drug",
      title: "Add a new patient drug",
      add_drug_form: {
        form: {
          coverage_info: {
            info: "COVERAGE INFO",
            covered_by_payer: "Drug not covered by payer"
          },
          drug_name: {
            label: "Drug Name *",
            placeholder: "Drug Name",
            required_message: "Drug name is required",
            search: "Type to search"
          },
          dosage: {
            label: "Dosage",
            placeholder: "Drug dosage",
            required_message: "Dosage is required",
            tooltip_text:
              "Dosage field accepts numbers, decimal point and dashes. For drugs which have more than one active ingredient, please enter each ingredient's dose separated by a dash. For example, 24-26 or 1.5-2.5",
            error_validation: "Must be a valid number. May contain a '-' separator"
          },
          dosage_unit: {
            label: "Unit",
            placeholder: "Dosage unit",
            required_message: "Dosage unit is required"
          },
          doses_per_day: {
            placeholder: "Doses per Day",
            required_message: "Number of doses per day is required"
          },
          units_per_day: {
            placeholder: "Units per day"
          },
          number_of_refills: {
            placeholder: "Number of Refills",
            required_message: "Please enter refill amount"
          },
          refill_frequency: {
            placeholder: "Refill Frequency (in days)"
          },
          date_picker: {
            placeholder: "Start date",
            required_message: "Start date is required"
          },
          administration_site: {
            placeholder: "Administration site",
            required_message: "Administration site is required"
          },
          num_of_cycles: {
            placeholder: "Number of Cycles",
            required_message: "Please enter the number of cycles"
          },
          physician: {
            label: "Physician",
            choose_physician: "Choose Physician"
          }
        }
      },
      edit_drug_form: {
        form: {
          edit_physician: "The choice of physician will impact all instances of the same drug."
        }
      }
    },
    form: {
      physician: {
        label: "Physician",
        placeholder: "-",
        error_required: "Please input physician",
        popover: {
          npi: "NPI",
          dea: "DEA",
          stateLicense: "State License",
          phone: "Phone",
          fax: "Fax",
          address: "Address"
        }
      },
      refills: {
        label: "Refills",
        placeholder: "Refills",
        error_required: "Please input Refills",
        zero_refills: "No refills"
      },
      order_type: {
        label: "Order Type",
        placeholder: "Order Type",
        free_text: "Free",
        replecement_text: "Replacement"
      },
      order_date: {
        label: "Order Date",
        placeholder: "Order Date",
        error_required: "Please input the order date"
      },
      admin_date: {
        label: "Admin Date",
        placeholder: "Admin Date",
        error_required: "Please input the admin date"
      },
      bill_voided: {
        label: "Bill Voided",
        placeholder: "Bill Voided",
        no_text: "No",
        yes_text: "Yes"
      },
      status: {
        label: "Status",
        placeholder: "Status"
      },
      quantity: {
        label: "Quantity",
        placeholder: "Quantity",
        error_required: "Please input the quantity"
      },
      dosage: {
        label: "Dosage",
        placeholder: "Dosage",
        error_required: "Please input the drug dosage",
        error_validation: "Must be a valid number. May contain a '-' separator",
        popover:
          "Dosage field accepts numbers, decimal point and dashes. For drugs with more than one active ingredient, enter each ingredient’s dose separated by a dash. For example, 24-26 or 1.5-2.5"
      },
      unit: {
        label: "Unit",
        placeholder: "Unit",
        error_required: "Please input the drug unit"
      },
      dosage_unit: {
        placeholder: "Dosage Unit"
      },
      cycle: {
        label: "Cycle",
        placeholder: "Cycle",
        error_required: "Please input cycle",
        per_patient: "Per Patient",
        per_cycle: "Per Cycle"
      },
      from_cycle: {
        label: "Apply from cycle",
        error_required: "Please input apply from cycle"
      },
      days_before: {
        label: "Days before/after admin",
        before: "Before",
        after: "After",
        error_required: "Please input days before/after administration"
      },
      days_between: {
        label: "Days between cycles",
        error_required: "Please input order frequency"
      },
      estimated_date: {
        label: "Estimated Delivery",
        placeholder: "Estimated Delivery Date",
        error_required: "Please input the estimated delivery date"
      },
      note: {
        label: "Note",
        placeholder: "Notes",
        error_required: "Please enter note",
        popover: "Enter note"
      },
      task: {
        label: "Task",
        placeholder: "Task text",
        error_required: "Please enter task",
        popover: "Enter task"
      },
      due_date: {
        label: "Due Date",
        placeholder: "Due Date",
        error_required: "Please input the task's due date"
      },
      assignee: {
        label: "Assignee",
        placeholder: "Assignee",
        error_required: "Please choose assignee",
        clear_assignee: "No assignee"
      },
      carrier: {
        label: "Carrier",
        placeholder: "Carrier",
        error_required: "Choose a carrier",
        fedex: "FedEx",
        ups: "UPS",
        usps: "USPS",
        dhl: "DHL",
        amazon: "Amazon"
      },
      rx_instructions: {
        label: "Rx instructions",
        empty_placeholder: "-",
        placeholder: "Enter instructions",
        error_required: "Rx instructions required"
      },
      rx_number: {
        label: "Rx number",
        empty_placeholder: "-",
        placeholder: "Enter number",
        error_required: "Rx number required"
      },
      tracking_number: {
        label: "Tracking No.",
        placeholder: "Tracking Number",
        error_required: "Please input one tracking number",
        error_pattern: "Tracking Number must contain only numbers and letters"
      }
    },
    support: {
      please_provide_relevant_details_below: "Please provide the relevant details below",
      title: "Contact Support: {{ name }}",
      disclaimer: "Please do not input protected health information (ePHI) in the subject",
      description: "Description *",
      subject: "Subject *",
      description_required: "Description is required",
      subject_required: "Subject is required"
    },
    header_searchbar: {
      searched_patients: "Patient",
      show_all_results: "Show {{totalPatients}} results",
      searched_patients_assignees: "Assignee",
      autocomplete_search_results_list: {
        name_id: "No patients found",
        dob: "For results, enter the patient’s full DOB.",
        phone_number: "For results, enter the patient’s full phone number.",
        social_security_number: "For results, enter the patient’s full SSN."
      },
      option_type_list: {
        name_id: "Name / ID",
        dob: "Date of Birth",
        phone: "Phone",
        ssn: "SSN"
      },
      placeholder: {
        name_id: "Search patients by Name, ID or MRN",
        dob: "MM/DD/YYYY",
        phone_number: "(000) 000-0000",
        social_security_number: "123-12-1234"
      },
      results_title: {
        dob: "DOB",
        phone_number: "PHONE",
        social_security_number: "SSN"
      }
    },
    assistance_programs: {
      search_placeholder: "Search by Program Name",
      table: {
        name: "Name",
        provided_by: "Provided By",
        type: "Type",
        Insurance: "Insurance",
        diagnosis: "Diagnosis",
        grant_amount: "Grant Amount",
        status: "Status"
      },
      filters: {
        title: "FILTERS",
        fund_type: "Fund Type",
        diagnosis_name: "Diagnosis Name",
        diagnosis_code: "Diagnosis ICD Code",
        insurance_type: "Insurance Type",
        drug_name: "Drug Name",
        allowed_states: "Allowed States",
        show_only: "Show only open funds"
      },
      modal: {
        benefits: "Benefits",
        eligibility: "Eligibility",
        clinical_information: "Clinical Information",
        application_renewal_time_type: {
          never_expires: "Never expires",
          time_to_renewal: "Expires {{applicationRenewalTimeInMonths}} months from first use or activation",
          expired_end_of_the_year: "Expires at the end of the calendar year"
        }
      }
    },
    patient_header: {
      more_info: {
        preferred_name: "Preferred Name",
        ssn: "SSN",
        email: "Email",
        address: "Address"
      }
    },
    new_patient: {
      form_fields: {
        first_name: "First Name *",
        last_name: "Last Name *",
        mrn: "MRN *",
        phone_number: "Phone Number *",
        dob: "Date of Birth *",
        middle_name: "Middle Name (Optional)",
        plan_markets: "Plan Market(s) *",
        drugs: "Drug(s) *",
        diagnoses: "Diagnoses *"
      },
      place_holders: {
        first_name: "Enter First Name",
        last_name: "Enter Last Name",
        mrn: "Enter MRN",
        middle_name: "Enter Middle Name",
        plan_markets: "Choose up to 3 coverage plan market(s)",
        drugs: "Enter drug name",
        diagnoses: "Enter diagnosis name or code"
      },
      minimize: "Minimize",
      find_ap_button: "Find Assistance Programs",
      find_ap_title: "Find Assistance Programs",
      find_ap_sub_title: "Please fill the information below to quickly find assistance programs for your patient.",
      what_next: "What would you like to do next?",
      patient_successfully_created: "Patient was successfully created!",
      save_and_find_ap: "Save Details and Find Programs",
      popover_subtitle:
        "Input the mandatory fields below. You always can add additional information as needed from the patient screen.",
      create_patient_button: "Create Patient",
      create_patient_tooltip: "Create a New Patient",
      editing_patient: "Editing Patient",
      adding_patient: "Adding Patient",
      failed_to_create_new_patient: "Failed to create a new patient",
      title: "Create a New Patient",
      adding_patient_message: "Please wait while we analyze the patient information...",
      title_editing: "Edit Patient",
      demographics: "Demographics",
      patient_label: "PATIENT NAME",
      email: "EMAIL",
      marital_status: "MARITAL STATUS",
      phone_number: "PHONE NUMBER",
      diagnosis: "Diagnosis",
      input_required: "This is required.",
      add_diagnosis: "Add Diagnosis",
      primary_diagnosis: "PRIMARY DIAGNOSIS",
      primary_diagnosis_camelCase: "Primary Diagnosis",
      diagnosis_uppercase: "DIAGNOSIS",
      date_of_birth_label: "DATE OF BIRTH",
      gender_label: "GENDER",
      gender_male: "Male",
      gender_female: "Female",
      address_label: "ADDRESS",
      household_size: "HOUSEHOLD SIZE",
      mrn_label: "MRN",
      physician_label: "PHYSICIAN",
      program_label: "PROGRAM",
      treatment_plan: "Treatment Plan",
      only_digits_rule: "Only digits are allowed",
      ssn_rule: "Please enter 9 digits",
      program_selection: "Program Selection",
      financial_and_insurance: "Financial and Insurance",
      financial: "Financial",
      primary_insurance: "PRIMARY INSURANCE",
      secondary_insurance: "SECONDARY INSURANCE",
      tertiary_insurance: "TERTIARY INSURANCE",
      financial_information: "FINANCIAL INFORMATION",
      effective_date: "Effective Date",
      patient_uninsured: "Patient is uninsured",
      add_policy: "Add Policy",
      other: "Other",
      other_uppercase: "OTHER",
      drug_event_uppercase: "DRUG EVENT",
      us_citizen: "US CITIZEN",
      drug_regimen_uppercase: "DRUG REGIMEN",
      radiotherapy_uppercase: "RADIOTHERAPY",
      imaging_uppercase: "IMAGING",
      visit_uppercase: "VISIT",
      other_payer_selected_message: "Payers added manually may not be supported for automatic benefit investigation.",
      no_automatic_investigation_message:
        "Payer is not currently supported for automatic benefit investigation and will require a manual benefit investigation by TailorMed’s Support team.",
      unknown_coverage_message:
        "Unknown coverage - The provided coverage information was not recognized by the system. Automatic benefits investigation may be affected.",
      drug_event: "DRUG EVENT",
      drug_regimen: "DRUG REGIMEN",
      radiotherapy: "RADIOTHERAPY EVENT",
      imaging: "IMAGING",
      visit: "VISIT",
      administration_site: {
        placeholder: "Administration Site",
        required: "Administration site is required"
      },
      next: "Next",
      submit: "Submit",
      update: "Update",
      add_treatment: "Add Treatment",
      add_event: "Add Event",
      cancel_modal_message:
        "You have unsaved patient details in your form. Leaving the form will discard any unsaved changes. What would you like to do?",
      stay_in_form: "Stay in Form",
      leave_anyway: "Leave anyway",
      cancel_modal_title: "Unsaved Patient Details",
      diagnosis_placeholder: "Select diagnosis or ICD code *",
      diagnosis_date_placeholder: "Diagnosis Date *",
      first_name_placeholder: "First Name",
      middle_name_placeholder: "Middle Name",
      last_name_placeholder: "Last Name",
      date_of_birth_placeholder: "Date of Birth",
      zip_code_placeholder: "ZIP Code",
      state_placeholder: "State",
      mrn_placeholder: "MRN",
      assistance_program_placeholder: "Assistance program",
      drug_name_placeholder: "Drug ame",
      unit_placeholder: "Select unit *",
      drug_dosage_placeholder: "Dosage",
      drug_unit_placeholder: "Dosage unit",
      cycles_placeholder: "Number of Cycles",
      order_frequency_placeholder: "Order Frequency *",
      cycles_frequency_placeholder: "Cycles Frequency (In Days)",
      current_cycle_placeholder: "Current cycle *",
      next_admin_date_placeholder: "Next admin date *",
      annual_income_placeholder: "Annual Income",
      household_size_placeholder: "Household Size",
      member_id_placeholder: "Member ID *",
      payer_name_placeholder: "Payer Name *",
      plan_market_placeholder: "Plan Market *",
      date_placeholder: "Date",
      treatment_placeholder: "Treatment",
      provider_placeholder: "Provider",
      number_of_fractions_placeholder: "Number of Fractions",
      imaging_area_placeholder: "Imaging Area",
      type_placeholder: "Type",
      drug_placeholder: "Drug Name",
      regimen_placeholder: "Regimen Name",
      treatment_id_placeholder: "Treatment ID",
      administration_type_placeholder: "Administration Type",
      doses_per_day_placeholder: "Doses Per Day",
      start_date_placeholder: "Start Date",
      diagnosis_tooltip: "Select diagnosis or ICD code",
      diagnosis_date_tooltip: "Diagnosis Date",
      assistance_program_tooltip: "Assistance program",
      drug_name_tooltip: "Drug name",
      drug_name_popover: "You may search for a drug by its generic or listed brand name, HCPC or NDC.",
      drug_brand_name_tooltip: "Drug brand name",
      unit_tooltip: "Select unit",
      cycles_tooltip: "Number of Cycles",
      order_frequency_tooltip: "Order Frequency",
      cycles_frequency_tooltip: "Cycles Frequency (In Days)",
      current_cycle_tooltip: "Current Cycle",
      next_admin_date_tooltip: "Next Admin Date",
      annual_income_tooltip: "Annual Income",
      household_size_tooltip: "Household Size",
      member_id_tooltip: "Member ID",
      payer_name_tooltip: "Payer Name",
      plan_market_tooltip: "Plan Market",
      date_tooltip: "Date",
      treatment_tooltip: "Treatment",
      provider_tooltip: "Provider",
      number_of_fractions_tooltip: "Number of Fractions",
      imaging_area_tooltip: "Imaging Area",
      type_tooltip: "Type",
      drug_tooltip: "Drug Name",
      regimen_tooltip: "Regimen Name",
      treatment_id_tooltip: "Treatment ID",
      administration_type_tooltip: "Administration Type",
      administration_site_tooltip: "Administration Site",
      doses_per_day_tooltip: "Doses Per Day",
      start_date_tooltip: "Start Date",
      effective_date_tooltip: "Effective Date",
      other_payer_exists: '"Other" payer already exists',
      other_payer_exists_content: `
      We have identified that the payers you manually added {{payerNames}}
      are already supported by the system. Below are the optional
      payer names that matches. Please choose the matching payer
      from the list:
      `,
      required_fields_error: "Please complete all required fields.",
      mrn_only_english: "MRN should include only numeric string or English letters.",
      mrn_must_contain_numbers: "MRN should contain both numbers and letters, or only numbers.",
      patient_already_exist_error: "The patient already exists. ",
      view_existing_patient_link: "View Patient"
    },
    date_range_filter: {
      date_range: "Date Range"
    },
    column_selector: "Column Selector",
    patientsTasks: {
      mrn: ", MRN: {{mrn}}",
      title: "Tasks for All Patients",
      tasks_for: "Tasks for",
      add_task: "Add Tasks",
      all_assignees: "All assignees",
      unassigned: "Unassigned",
      show_all: "Show all",
      not_done: "Not done",
      done: "Done",
      all_times: "All times",
      within_a_week: "Within a week",
      within_a_month: "Within a month",
      date_range: "Date range",
      back_to_tasks: "Back to tasks",
      task: "Task *",
      tasks: "Tasks",
      this_is_required: "This is required",
      due_date: "Due date",
      assignee: "Assignee",
      clear_assignee: "Clear assignee",
      add: "Add",
      update: "Update",
      load_more: "Load more",
      view_program: "View Program",
      export: {
        title: "Export",
        download_tasks: "Download displayed tasks",
        export_successful: "Export Successful.",
        export_failed: "Export Failed.",
        fileNamePrefix: "TM_FN_TaskList",
        sheetName: "Tasks",
        patient_id: "Patient ID",
        patient_mrn: "Patient MRN",
        patient_name: "Patient Name",
        assignee: "Assignee",
        task: "Task",
        due_date: "Due Date",
        status: "Status",
        assistance_program: "Assistance Program",
        created_at: "Created At",
        updated_at: "Updated At",
        created_by: "Created By",
        updated_by: "Updated By",
        done: "Done",
        not_done: "Not Done",
        completed_at: "Completed At",
        completed_by: "Completed By"
      }
    },
    patientTopSection: {
      mrn: "MRN",
      diagnoses: "Diagnoses",
      x_more_diagnoses: "+{{count}} more diagnoses",
      dob: "DOB",
      age: "Age",
      phone_number: "Phone",
      employer: "Employer",
      status: "Status",
      import_status: "Import Status",
      assignees: "Assignees",
      facility: "Facility",
      physician: "Physician",
      follow_up_date: "Follow-up date",
      financial: "Financial",
      fpl: "FPL",
      insurance: "Insurance",
      show_more: "Show More",
      show_less: "Show Less",
      high_priority: "High Priority",
      exclude_high_priority: "Exclude program from High Priority",
      exclude_patient_from_high_priority: "Exclude patient from High Priority list (from all programs)",
      deceased: "Deceased",
      data_conflicts: "IMPORTED DATA CONFLICTS",
      preferred_language: "Preferred Language",
      caregiver: "Caregiver",
      relation_to_patient: "Relation to Patient",
      success_message_update_preferred_language: "Updated patient preferred language successfully",
      failed_message_update_preferred_language: "Failed to update patient preferred language",
      contact_details: "Contact Details",
      alternate_contact_details: "Alternate Contact Details",
      name: "Name",
      email: "Email",
      contact_instead_of_patient: "Contact Instead of Patient",
      ok_to_speak_to: "Ok to Speak to",
      more_insurance: "more insurance"
    },
    totalUniqueDrugs: {
      tooltip: "Active medications have a medication event in the last 6 months",
      title: "Active Medications",
      show_drugs_button: "Show Drugs",
      non_iv_drugs: "Non-IV Drugs",
      iv_drugs: "IV Drugs"
    },
    patientCoverageSummary: {
      number_of_coverages: "{{number}} Coverage",
      coverage_summary: "Coverage Summary {{amount}}",
      no_coverages_found: "No coverages found",
      add_coverage: "Add Coverage",
      view_all: "View All",
      no_oop_limit: "No OOP Limit",
      remaining_oop: "Remaining OOP: ${{remainingOOP}}"
    },
    patientRSActions: {
      attachments: "Attachments",
      tasks: "Tasks",
      attachments_for: "Attachments for",
      by_date_of: "By date (Oldest first)",
      by_date_nf: "By date (Newest first)",
      by_name_az: "By name (A -> Z)",
      by_name_za: "By name (Z -> A)",
      all: "All",
      FN_attach: "FN attach, only",
      Pharmacy_attach: "Pharmacy attach, only",
      uploaded_at: "Uploaded at",
      attachments_disabled: "You don’t have permissions to download this file",
      message_confirm: "Are you sure you want to delete this file?",
      notes: {
        notes: "Notes",
        notes_for: "Notes for {{name}}",
        add_note: "Add note",
        delete_note_popup_title: "Confirm Delete",
        delete_note_popup_content: "Are you sure that you want to delete this note?"
      }
    },
    patientOverview: {
      overview: "Overview",
      disable_patient_report: "Coming Soon - New and Improved Patient Report",
      patient_journey: "Patient Journey",
      coverage_details: "Coverage Details",
      potential_savings: "Potential Savings",
      applications: "Applications",
      enrollments: "Enrollments",
      treatment: "All Medications",
      showing_events: "Showing events {{startingNumber}} - {{endingNumber}} out of {{endingNumber}} events",
      journey_impact: "Journey Impact",
      coverage_summary: "Coverage Summary",
      estimated_oop: "Estimated OOP",
      oop_estimatation: "OOP Estimation",
      oop_year: "Year",
      oop_source: "Source",
      edit_oop: "Edit OOP",
      total: "Total ({{year}})",
      remaining: "Remaining ({{year}})",
      please_check_in_again: "Please check it again in a while.",
      automatic_benefit_investigation_not_supported_will_update:
        "Automatic benefit investigation is not supported for this patient due to lack of coverage data. The system will update once coverage data is received.",
      automatic_benefit_investigation_not_supported:
        "Automatic benefit investigation is not supported for this patient.",
      no_support_for_automatic_benefit_investigation_lack_coverage_data:
        "No support for automatic benefit investigation due to lack of coverage data.",
      no_support_for_automatic_benefit_investigation: "No support for automatic benefit investigation.",
      no_potential_savings_found: "No Programs to Show.",
      add_more_data:
        "Add the patient’s drugs, diagnosis, and coverage details from Manage Patient Details to find potential savings recommendations.",
      request_manual_analysis: "Request Manual Analysis",
      manual_analysis_requested: "Manual Analysis Requested",
      automatic_oop_estimation_not_supported: "Automatic Out-of-pocket estimation is not supported for this patient.",
      oop_estimation_not_performed: "OOP Estimation not performed.",
      in_analysis: "In Analysis",
      travel_cost: "Travel Cost",
      sick_days: "Sick Days",
      caregiver_days: "Caregiver Days",
      approved: "Approved",
      applicable: "Applicable",
      assistance_programs: "{{number}} Assistance programs:",
      government_plans: "Government plans",
      deductible: "Deductible",
      copay_assistance: "Copay assistance",
      free_drugs: "Free drugs",
      others: "Others",
      living_expenses: "Living expenses",
      assuming_continued_coverage: "Assuming continued coverage",
      oop_next_six_months: "OOP (next 6 months)",
      oop_unitl: "OOP (until",
      oop_total_journey: "OOP (total journey)",
      oop_estimatation_error: "Please input OOP Estimation!",
      year_error: "Please input OOP Year!",
      done: "Done",
      cancel: "Cancel",
      oop_table_oop_title: "OOP",
      oop_table_year_title: "For Year",
      oop_table_source_title: "Source",
      oop_table_user_title: "Name",
      oop_table_date_title: "Date"
    },
    patientJourney: {
      event_description: "Event description",
      estimated_oop_cost: "Estimated out-of-pocket cost",
      drug: "Drug",
      cycles: "Cycles",
      cycle: "Cycle",
      dosage: "Dosage",
      generic_name: "Generic Name",
      brand_name: "Brand Name",
      jcode: "Jcode/HCPC",
      summary: "Summary",
      name: "Name",
      admin_type: "Admin Type",
      code: "Code",
      price: "Price",
      oop: "OOP",
      detailed: "Detailed",
      date: "Date",
      drug_name: "Drug Name",
      coverage_details: "Coverage Details",
      admin: "Admin",
      total_oop: "Total OOP",
      not_guaranteed_price_estimate:
        "This is not a guaranteed price estimate. Out of pocket estimates may change based of payers decisions, changes to care plans and internal hospital decisions.",
      applicable_potential_savings: "Applicable Potential Savings",
      matches_assistance_programs: "Matches of Assistance Programs",
      assistance_program: "Assistance Program",
      update: "Update",
      drug_coverage: "Drug Coverage",
      to_detect: "to detect additional assistance programs",
      show_info: "Show Info",
      patient_journey_details_unavailable: "Journey details are currently unavailable",
      no_treatment_information_for_this_patient:
        "It seems that there is no treatments information for this patient. The system will update this section as it will become available.",
      the_system_will_update:
        "The system will update this section as it will become available. Please check again in a while.",
      government_plan: "Government Plan",
      in_analysis: "In Analysis",
      copay_foundation: "Copay Foundation",
      free_drug: "Free Drug",
      living_and_transport_expenses: "Living and Transport Expenses",
      other: "Other",
      government_plans: "Government Plans",
      ordering_physicians: "Ordering Physician(s):",
      ordering_physicians_description: "The physician(s) who ordered the drugs specified in the journey event",
      drug_events: "Drug Events",
      more_details: "More Details",
      cost_estimation: "Cost Estimation"
    },
    patientClassicCoverageDetailsOverview: {
      payer_name: "Payer Name",
      expired: "EXPIRED",
      reactivate: "Reactivate",
      fill_required_fields: "Please fill the required fields",
      saving_changes: "Saving changes",
      edit: "Edit",
      payer: "Payer",
      delete: "Delete",
      switch_with: "Switch with",
      other: "Other",
      plan_started: "PLAN STARTED",
      plan_ends: "PLAN ENDS",
      group_id: "Group ID",
      member_id: "Member ID",
      plan_market: "Plan Market",
      search_by_payer: "Search by payer name",
      select_plan_market: "Select Plan Market",
      insert_member_id: "Please insert member ID",
      search_payer: "Search Payer",
      delete_confirmation: "Are you sure you wish to delete this coverage?",
      delete_with_type_confirmation:
        "Are you sure you want to delete the {{coverageType}} coverage ? This will change the order of the other coverages.",
      delete_coverage: "Delete Coverage",
      yes_delete: "Yes, Delete",
      add_new_coverage: "Add New Coverage",
      add_coverage: "Add Coverage",
      coverage_with_the_same_payer_name_and_member_ID:
        "A coverage with the same Payer name and Member ID already exists",
      prescription_benefit: "Prescription Benefit",
      rx_member_id: "Rx Member ID",
      rx_plan_name: "Rx Plan Name",
      rx_bin: "Rx BIN",
      rx_pcn: "Rx PCN",
      rx_group: "Rx GRP",
      benefit_type: "Coverage Type", // we use coverage type instead of benefit type since the user is adding a coverage , in our system coverage type is referred to primary, secondary, tertiary
      choose_benefit_type: "Choose Coverage Type",
      prescription_benefit_type: "Prescription",
      medical_benefit_type: "Medical",
      medical_and_prescription_benefit_type: "Medical + Prescription",
      lose_filled_data_title: "Coverage Type",
      lose_filled_data_content:
        "Changing coverage types will remove {{benefitType}} information. Would you like to continue?",
      lose_filled_data_confirmation: "Continue",
      lose_filled_data_cancel: "Cancel"
    },
    patientCoverageDetailsTabs: {
      delete_confirmation: "Are you sure you wish to delete these coverages?",
      primary_coverage: "Primary Coverage",
      secondary_coverage: "Secondary Coverage",
      tertiary_coverage: "Tertiary Coverage",
      unknown_coverages: "Unknown Coverages",
      unspecified_coverages: "Unspecified Coverages",
      overview: "Overview",
      drugs: "Drugs",
      benefits: "Benefits",
      documents: "Documents",
      payer_details: "Payer Details",
      coverages_can_always_be_added: "Coverages can always be added"
    },
    coverages: {
      save_changes: "OK, Save Changes",
      redirect_message: "We are redirecting you to a different page, any made changes will be saved.",
      changing_coverage_details_message:
        "Changing the coverage details may initiate a new benefit investigation process. Calculated insurance information may be affected."
    },
    patientCoverageDetails: {
      no_coverage_info: "No Coverage info set yet",
      no_coverage_yet: "No coverages yet",
      payer_name: "Payer Name",
      plan_market: "Plan Market",
      plan_name: "Plan Name",
      in_network: "In Network",
      out_network: "Out Network",
      out_of_network: "Out-of-Network",
      payer: "Payer",
      group_id: "Group ID",
      member_id: "Member ID",
      plan_restart: "Plan Restart",
      coverage: "Coverage",
      coinsurance: "Coinsurance",
      summary_of_benefits: "Summary of Benefits",
      evidence_of_coverage: "Evidence of Coverage",
      drug_formularies: "Drug Formularies",
      provider_directory: "Provider Directory",
      phone_number: "Phone Number",
      website: "Website",
      address: "Address",
      coverage_data_check_up: "Coverage data check up",
      click_get_coverage: "Click ‘Get coverage’ to obtain the patient’s coverage information",
      get_coverages: "Get coverages",
      initial: "Initial",
      latest: "latest",
      refresh: "Refresh",
      coverage_details_unavailable: "Coverage details are currently unavailable",
      the_system_will_update:
        "The system will update this section as it will become available. Please check again in a while.",
      deductible: "Deductible",
      oop_max: "OOP Max",
      coverage_data_refreshed_success: "Coverage data has been refreshed successfully.",
      coverage_data_refreshed_failure: "Coverage data has not been refreshed."
    },
    potentialSavingsModal: {
      benefits: "Benefits",
      eligibility_criteria: "Eligibility Criteria",
      potential_savings: "Potential Savings",
      application_link: "Application Link",
      program_website: "Program Website",
      current_status: "Current Status",
      used: "used",
      new_application_header: "New Application Creation",
      new_application_content: "You are about to create a new application for {{programName}}."
    },
    errorMessages: {
      update_patient_details_failed: "Failed to update patient details",
      failed_to_get_imported_statuses: "Failed to get imported Statuses",
      failed_to_update_assignees: "Failed to update assignees",
      failed_to_update_patient_status: "Failed to update patient status",
      error_while_downloading_form: "An error has occurred downloading the form",
      failed_to_add_drugs: "Failed to add drugs",
      failed_to_check_if_pdf_exists: "Failed to check if pdf exists",
      drug_calculation_failed: "Drug cost calculation failed",
      failed_to_update_drugs_calculations: "Failed to update drugs calculations",
      failed_to_get_drugs: "Failed to get drugs",
      failed_to_create_patient: "Failed to create patient",
      failed_to_edit_patient: "Failed to edit patient",
      failed_to_uninsure_patient: "Failed to delete patient insurances",
      failed_to_edit_coverage: "Failed to edit coverage",
      failed_to_create_coverage: "Failed to create coverage",
      failed_to_delete_coverage: "Failed to delete coverage",
      patient_creation_failure: "Patient Creation Failure",
      please_contact_support: "Please contact support.",
      diagnosis_names: "Failed to get diagnosis names.",
      diagnosis_icds: "Failed to get diagnosis ICDs.",
      diagnosis_search: "Failed to search diagnoses.",
      drugs_search: "Failed to search drugs.",
      regimens_search: "Failed to search regimens.",
      payers_search: "Failed to search payers.",
      patient_data: "Failed to get patient data.",
      patient_starred: "Failed to edit patient starred.",
      top_opportunity_patients: "Failed to get Top opportunity patients",
      failed_to_update_physician: "Failed to update physician",
      failed_to_update_facility: "Failed to update facility",
      filterlist_starred: "Failed to update starred status.",
      failed_conflict_resolution: "Conflict resolution could not be saved",
      failed_to_add_pap: "Assistance Program could not be added",
      failed_to_add_pap_drug: "Drug could not be added",
      failed_to_add_pap_duplicate: "Assistance Program already exists for patient",
      failed_to_update_pap_information: "Failed to update Potential Savings information",
      enrollment_form_failed_to_create: "Enrollment form failed to create.",
      failed_to_load_pdf_document: "Failed to preview the file.",
      reload: "Reload",
      signature_remove_fail: "signature requests failed to delete.",
      print_attachment_failed: "Print attachment failed",
      application_form_update_failed: "Something went wrong. Please refresh the page",
      should_be_less_than: "{{fieldName}} should be less than {{maxCharacter}} character"
    },
    successMessages: {
      updated_patient_details: "Patient details updated successfully",
      conflicts_solved_successfully: "Conflicts resolved successfully",
      patient_details_updated_successfully: "Patient details updated successfully",
      updated_assignees_successfully: "Updated assignees Successfully",
      updated_patient_status_successfully: "Updated patient status successfully",
      drug_added_successfully: "Drug was added successfully",
      deleted_patient_coverages_successfully: "Deleted patient coverages successfully",
      deleted_coverage_successfully: "Deleted coverage successfully",
      coverage_created_successfully: "Coverage created successfully",
      coverage_updated_successfully: "Coverage updated successfully",
      successfully_added: "Patient {{patientId}} created - analyzing data in progress",
      successfully_edited: "Patient {{patientId}} edited successfully",
      successfully_updated_physician: "Updated physician Successfully",
      successfully_updated_facility: "Updated facility Successfully",
      successfully_conflict_resolution: "Conflict resolution was saved successfully",
      apa_created_successfully: "New application has been created",
      copy_to_clipboard_successfully: "The Information Was Successfully Copied To Clipboard.",
      enrollment_form_was_generated_successfully: "Enrollment form generated successfully",
      deleteSignature: "Request for signatures was deleted successfully."
    },
    managePatientDetails: {
      editInWorksheet: "Edit In worksheet",
      cancel_discard: "Back",
      date_of_birth: "Date of Birth",
      preferred_language: "Preferred Language",
      placeholder: { preferred_language: " Select Preferred Language" },
      phone: "Phone",
      phone_required: "Phone is required",
      alternate_contact_phone: "Alternate Contact Phone",
      full_name: "Full Name",
      alternate_contact_full_name: "Alternate Contact Full Name",
      alternate_contact_email: "Alternate Contact Email",
      contact_instead_of_patient: "Contact instead of patient",
      ok_to_speak_to: "Ok to speak to",
      full_name_required: "Name is required",
      relation_to_patient: "Relation to Patient",
      manage_patient_details: "Manage Patient Details",
      personal: "Personal",
      financial: "Financial",
      medical: "Medical",
      medications: "Medications",
      patient_is_uninsured: "This patient is uninsured",
      email: "Email",
      phone_number: "Phone Number",
      address: "Address",
      city: "City",
      state: "State",
      zip: "ZIP",
      marital_status: "Marital Status",
      marital_status_placeholder: "Choose one",
      undefined: "Undefined",
      single: "Single",
      married: "Married",
      separated: "Separated",
      divorced: "Divorced",
      widowed: "Widowed",
      social_security_number: "Social Security Number",
      household_size: "Household Size",
      adults: "Adults",
      children: "Children",
      total: "Total",
      us_citizen: "US Citizen",
      legal_resident: "Legal Resident",
      veteran: "Veteran",
      disabled: "Disabled",
      patient_is_deceased: "This patient is deceased",
      reset_patient_data: "Reset patient data",
      done: "Done",
      patient_data_updated: "Patient data has been updated successfully",
      patient_personal_data_could_not_be_updated: "Patient personal data could not be updated",
      the_patient_data_has_been_unlocked: "The patient data has been unlocked successfully",
      all_manual_changes_will_be_reset:
        "All manual changes and additions will be reset, and the patient`s data will be set automatically according to the data arriving from the ERM. This action can`t be reverted",
      cancel: "Cancel",
      approve: "Approve",
      patient_weight_lbs: "Patient weight (lbs)",
      monthly_income: "Monthly Income",
      annual_income: "Annual Income",
      monthly_household_income: "Monthly Household Income",
      annual_household_income: "Annual Household Income",
      patient_allergies: "Allergies",
      patient_allergies_placeholder: "Enter patient's allergies",
      patient_weight: "Weight",
      patient_weight_placeholder: "Weight",
      patient_height: "Patient Height",
      patient_height_placeholder: "Height",
      monthly: "Monthly",
      yearly: "Yearly",
      salary: "Salary",
      social_security: "Social Security",
      disability: "Disability",
      unemployment: "Unemployment",
      pension: "Pension",
      stocks: "Stocks",
      dividends: "Dividends",
      child_support: "Child Support",
      food_stamps: "Food Stamps",
      other: "Other",
      fpl: "FPL",
      enter_amount: "Enter Amount",
      fpl_is_calculated: " is calculated based on household size and gross annual household income",
      income_verification_date: "Income Verification Date",
      files_taxes: "Files Taxes",
      household_Size: "Household Size",
      patient_consent_date: "Patient Consent Date",
      drugName: "Drug",
      proof_of_income_information: "Proof of Income Information",
      proof_of_income_information_placeholder: "Enter Details",
      name: "Name",
      cycles: "Cycles",
      admin_type: "Admin Type",
      start_date: "Start Date",
      end_date: "End Date",
      visibility: "Visibility",
      physician: "Physician",
      unhide: "Unhide",
      hide_drugs: "Hide drug from all screens, calculations and approved savings matching",
      please_wait_updating_relevant: "Please wait. Updating relevant assistance programs and OOP estimation",
      mark_drug_as: "Mark {{drugName}} as {{coveredOrUncovered}} by payer",
      covered: "covered",
      uncovered: "uncovered",
      confirm: "Confirm",
      confirm_drug_discontinued_date: "Confirm drug discontinued date",
      are_you_sure_discontinue_drug:
        "Are you sure you want to discontinue this drug? Once the drug is discontinued, you will not be able to undo this action.",
      please_wait_discontinued_date: "Please wait while discontinued date is updated...",
      please_wait_drug_visibility: "Please wait while drug visibility preference is being updated...",
      drug_visibility_updated: "Drug visibility preference updated successfully",
      assistance_programs_updated: "assistance programs updated: {{addedAmount}} added, {{removedAmount}} removed",
      youve_just_discontinued_drug:
        "You've just discontinued this drug. Note that if this drug arrives again in the data after TODAY, it will be created",
      no_treatment_data: "No treatment data specified for patient",
      cancel_modal_title: "Unsaved Changes",
      selecting_us_citizen: "Selecting 'US citizen' will automatically check 'Legal Resident' as well",
      usPhonePattern: "(000) 000-0000",
      please_enter_digits: "Please enter 10 digits.",
      coverage: "Coverage",
      select_date: "Select a date",
      conflicts_detected: "Conflicts Detected",
      email_placeholder: "name@mail.com",
      email_invalid: "Email isn't valid",
      email_required: "Email is required",
      social_security_number_placeholder: "123-12-1234",
      address_placeholder: "Olvera Street",
      state_placeholder: "Select State",
      city_placeholder: "Los Angeles",
      zip_placeholder: "Ex. 647483",
      add_diagnosis: "+ Add Diagnosis",
      diagnosis: "Diagnosis",
      last_insurance_reviewed_by: "Last Insurance Review by {{user}} on",
      mark_as_reviewed: "Mark as reviewed",
      insurances_not_yet_reviewed: "Last Insurance Review on",
      last_insurance_reviewed_info:
        "Manually update the date when the patient’s insurance was last reviewed. This date will not update automatically.",
      income_breakdown_modal_approve: "Ok",
      income_breakdown_modal_title: "Income Breakdown Edit",
      income_breakdown_modal_body: 'Switching to "Income Breakdown" will delete the current total amounts.',
      medications_section: {
        add_medication: "+ Add Medication",
        manually_added_medications: "Manually Added Medications",
        imported_medications: "Imported Medications",
        medication_modal: {
          cancel: "Cancel",
          update: "Update",
          add_medication: "Add Medication",
          edit_medication: "Edit Medication",
          add_new_medication: "Add New Medication",
          medication_form: {
            coverage_info: {
              info: "COVERAGE INFO",
              covered_by_payer: "Drug not covered by payer"
            },
            drug_name: {
              label: "Drug Name",
              placeholder: "Enter any drug",
              search_placeholder: "Search for any drug"
            },
            dose_form: {
              label: "Dose Form",
              placeholder: "Select Dose Form"
            },
            strength: {
              label: "Strength",
              placeholder: "Select Strength"
            },
            dosage: {
              label: "Dosage",
              placeholder: "Dosage Amount",
              validation_error: "Must be a valid number. May contain a '-' separator"
            },
            dosage_unit: {
              label: "Unit",
              placeholder: "Dosage unit",
              required_message: "Dosage unit is required"
            },
            doses_per_day: {
              placeholder: "Doses per Day",
              required_message: "Number of doses per day is required"
            },
            units_per_day: {
              label: "Units per day",
              placeholder: "0"
            },
            num_of_cycles: {
              label: "Number of Fills / Cycles",
              addon_before: "No.",
              placeholder: "0",
              required_message: "Please enter the number of cycles"
            },
            days_supply: {
              label: "Days Supply",
              placeholder: "0",
              required_message: "Days supply is required"
            },
            start_date: {
              label: "Start date",
              required_message: "Start date is required"
            },
            administration_site: {
              placeholder: "Administration site",
              required_message: "Administration site is required"
            },
            physician: {
              label: "Physician",
              choose_physician: "Choose Physician",
              edit_physician: "The choice of physician will impact all instances of the same drug."
            }
          }
        }
      }
    },
    patientPrint: {
      financial_navigation_report: "Financial Navigation Report",
      patient_details: "Patient Details",
      treatment_plan: "Treatment Plan",
      recommended_rograms: "Recommended Programs",
      out_of_pocket_estimation: "Out Of Pocket Estimation",
      diagnosis: "Diagnosis",
      welcomeMessage: "Dear {{patientName}}, we gathered for you the following details",
      treatment: "Treatment",
      oop_estimation: "OOP Estimation",
      travel_cost: "Travel Cost",
      sick_days: "Sick Days",
      caregiver_days: "Caregiver Days"
    },
    patientPotentialSavings: {
      add_details: "Add details",
      add: "Add",
      phone1: "Phone 1",
      phone2: "Phone 2",
      fax: "Fax",
      missing_FPL_details: "Missing <1>FPL</1> details",
      FPL_exceeds_max_FPL: "<1>FPL</1> exceeds program limit",
      FPL: "FPL",
      FPL_exceeds_max_FPL_long_message:
        "Patient's <1>FPL</1> exceeds program limit - please contact assistance program sponsor to confirm eligibility.",
      missing_FPL_details_long_message:
        "Patient’s <1>FPL</1> details are missing - the patient might not be eligible for this program",
      no_programs_to_show: "No Programs to Show",
      reject_reason_list: "Please select a reject reason from the list below:",
      add_more_data:
        "Patient’s {{missingElements}} details are missing. Add details to refine potential savings recommendations",
      reject_reason: "Reject Reason",
      rejectReasonList: {
        patient_not_eligible: "The patient is not eligible",
        patient_not_receiving: "The patient is not receiving the relevant medication",
        patient_not_active_treatment: "The patient is not in active treatment",
        demographic_related: "Demographic related (FPL, Age or Location)",
        diagnosis_not_eligible: "Diagnosis not eligible",
        coverage_not_eligible: "Coverage not eligible",
        patient_sufficiently_covered: "The patient is sufficiently covered by their insurance",
        oop_limit_reached: "The OOP limit has been reached",
        patient_already_receiving_fn: "The patient is already receiving financial assistance",
        patient_rejected_assistance: "The patient rejected assistance",
        patient_receiving_assistance_different_facility: "The patient is receiving assistance in a different facility"
      },
      search: "Search",
      add_program: "Add Program",
      assistance_programs_matches: "Matches of Assistance Programs",
      program_type: "Program Type",
      copay_card: "Copay Card",
      copay: "Copay Foundation",
      free_drug: "Free/Replacement Drug Program",
      government_plan: "Government Plans",
      living_expenses: "Living Expenses",
      government_plans: "Government Plans",
      internal: "Internal",
      all_program_types: "All Program Types",
      program_types_placeholder: "Please Select Program Types",
      no_type_selected: "No Type Selected",
      please_select: "Please select",
      program_status: "Program Status",
      not_seeing_a_program: "Not seeing a program you expect to see here?",
      check: "Check",
      drugs_coverage: "Drugs Coverage",
      coverage_information_unavailable: "Coverage information is unavailable. Showing all relevant programs.",
      update_drug_coverage: "Update Drug Coverage to detect additional assistance programs.",
      update_coverage: "Update Coverage",
      drug_coverage_popup:
        "In case the patient has uncovered drugs it is necessary to update the system in order to receive the most relevant assistance programs.",
      high_priority: "High Priority",
      enrolled_by_tailorMed: "Enrolled by TailorMed",
      create_application: "Create Application",
      express_enroll: "Express Enroll",
      renew_application: "Renew",
      edit_application: "Edit Application",
      not_relevant: "Set as Not Relevant",
      not_interested: "Not Interested",
      ineligible: "Ineligible",
      application_not_returned: "Application Not Returned",
      lookback_period: "Lookback Period",
      x_months_lookback: "{{count}} month lookback period",
      x_days_lookback: "{{count}} day lookback period",
      no_lookback_period: "No lookback period",
      application_status: "Application Status",
      enrollment_info: "Enrollment Information",
      who_can_enroll: "Who Can Enroll",
      patient_signature_required: "Patient Signature Required",
      patient_consent_required: "Required",
      patient_consent_not_required: "Not Required",
      benefits: "Benefits",
      eligibility: "Eligibility",
      clinical_information: "Clinical Information",
      contact_details: "Contact Details",
      enrollment_expiration_date: "Enrollment Expiration Date",
      offer_expiration_date: "Offer Expiration Date",
      maximum_number_of_uses: "Maximum Number of Uses",
      last_update: "Last Updated",
      program_details: "Program Details",
      insurance_criterias: "Insurance Criteria(s)",
      income_required: "Income Required (FPL)",
      program_eligibilitys: "Program Eligibility(s)",
      drugs: "Drug(s)",
      conditions: "Condition(s)",
      application_link: "Application Link",
      program_website: "Program Website",
      pharmacy_website: "Pharmacy Website",
      bin: "BIN",
      pcn: "PCN",
      group_number: "Group Number",
      ap_last_updated: "AP Last Updated",
      all_programs_types: "All Programs Types",
      open: "Open",
      closed: "Closed",
      waitlist: "Accepting Waitlist",
      waitlist_tooltip: "Accepting Patients to the Waitlist",
      waitlist_only_tooltip: "Processing Patients on the Waitlist",
      waitlist_only: "Processing Waitlist",
      confirm_not_interested: "Not interested Update",
      confirm_ineligible: "Ineligible Update",
      confirm_not_returned: "Not returned Update",
      mark_not_interested: 'You are about to set this patient as not interested in  "{{programName}}".',
      mark_ineligible: 'You are about to set this patient as ineligible for "{{programName}}".',
      mark_not_returned: 'You are about to set "{{programName}}"as not returned for this patient.',
      cancel: "Cancel",
      remove_program: "Remove Program",
      remove_program_content:
        'You are about to remove "{{programName}}" from the patient’s list. Please note the reason.',
      remove_program_reason: "Removal reason",
      confirm: "Confirm",
      last_program_update: "Last Program Update",
      description: "Description",
      disable_tooltip: "Confirm the checkbox disclaimer to enable",
      disable_tooltip_novartis:
        "Notice the patient does not have a commercial insurance and therefore not eligible. Please update manage patient details if the patient insurance details are inaccurate."
    },
    renew_application_popup: {
      renew_popup_header: "Application Renewal",
      renew_application_content: "You are about to renew the application for {{programName}}."
    },
    patientApplications: {
      enrollment_information: "Patient Enrollment Information",
      last_changed_on: "Last changed on",
      person_you_spoke_to: "Name Of Person You Spoke To",
      verified_with: "Verified With",
      mail_tracking_outbound: "Mail Tracking - Outbound",
      mail_tracking_inbound: "Mail Tracking - Inbound",
      solving_conflict_failed: "Failed to solve conflict",
      application_valid_through: "Application Valid Through:",
      available_funds: "Available Funds:",
      application_details: "Application details",
      express_enroll_header: "Enrolling {{patientName}} in {{programName}}",
      send_enrollment: "Send Enrollment",
      not_fn_user_phi: "Downloading this form is not allowed: Must be a Financial Navigator with PHI access",
      failed_update_used_amount: "Failed to update used amount",
      prepare: "Prepare",
      applied: "Applied",
      applied_to_waitlist: "Applied To Waitlist",
      approved: "Approved",
      canceled: "Canceled",
      expired: "Expired",
      exhausted: "Exhausted",
      denied: "Denied",
      ineligible: "Ineligible",
      not_interested: "Not Interested",
      not_returned: "Not Returned",
      missing_information: "Missing Information",
      temporarily_approved: "Temporarily Approved",
      enrollments: "All Enrollments",
      application_for: "Application for",
      status: "Status",
      last_change_by: "Last change by",
      approval_details: "Approval Details",
      program_type: "Program Type",
      potential_savings: "Potential Savings",
      used: "Used",
      approved_amount: "Approved Amount",
      approved_amount_required: "Approved amount is required",
      brand_name_required: "Brand name is required",
      applied_required: "Applied date is required",
      used_amount: "Used Amount",
      update: "Update",
      edit: "Edit",
      sent_to_patient: "Sent to patient",
      received_from_patient: "Received from patient",
      sent_to_physician: "Sent to physician",
      received_from_physician: "Received from physician",
      application_id: "Application ID",
      waitlist_application_id: "Application Waitlist ID",
      x_months_lookback: "Program definition - {{count}} month",
      x_days_lookback: "Program definition - {{count}} day",
      no_lookback_period: "Program definition - no lookback",
      lookback_period_starts: "Lookback period starts",
      valid_through: "Valid through",
      indefinitely: "Indefinitely",
      accounting_date: "Accounting date (operation use only!)",
      cancel: "Cancel",
      save: "Save",
      member_id: "Member ID",
      valid_as_long_as: "Valid as long as the patient is in relevant active treatment.",
      manage_free_drug_orders_in_pharmacy: "Manage free drug orders in TailorMed Pharmacy",
      drug_type: "Drug type",
      free_drug: "Free drug",
      replacement_drug: "Replacement drug",
      drug_type_required: "Drug type is required",
      bin: "BIN",
      pcn: "PCN",
      group: "Group",
      copay_card: "Copay Card",
      expiry_date: "Expiry date",
      cvv: "CVV",
      date: "Date",
      relevant_drugs: "Relevant drugs",
      drug_name: "Brand name",
      no_relevant_drugs: "No relevant drugs associated with the assistance program",
      no_other_brands: "No other drugs to switch to on this assistance program",
      canceled_date: "Canceled date",
      denied_date: "Denied date",
      denied_reason: "Denied reason",
      ineligible_date: "Ineligible date",
      not_interested_date: "Not interested date",
      not_returned_date: "Not returned date",
      missing_information_date: "Missing information date",
      saving_application_details: "Saving application details",
      previous_application_details_summary: "Previous Application Details Summary",
      click_autofill_or_fill:
        "Click on Autofill to automatically fill the application's PDF, or choose Provider Application or Pharmacy Application to fill the application online and upload the application form.",
      click_autofill: "Click on Autofill to automatically fill the application's PDF and upload the application form.",
      fill_provider: "Choose Provider Application to fill the application online and upload the application form.",
      fill_pharmacy: "Choose Pharmacy Application to fill the application online and upload the application form.",
      no_autofill_or_fill: "Autofill and Online Applications are not supported for this application.",
      click_autofill_and_provider:
        "Click on Autofill to automatically fill the application's PDF, or choose Provider Application to fill the application online and upload the application form.",
      click_autofill_and_pharmacy:
        "Click on Autofill to automatically fill the application's PDF, or choose Pharmacy Application to fill the application online and upload the application form.",
      fill_provider_and_pharmacy:
        "Choose Provider Application or Pharmacy Application to fill the application online and upload the application form.",
      autofill: "Autofill",
      provider_application: "Provider Application",
      pharmacy_application: "Pharmacy Application",
      generate_form: "Generate Form",
      unsaved_application_details: "Unsaved Application Details",
      you_have_unsaved_application_details:
        "You have unsaved application details in your form. Leaving the form will discard any unsaved changes.",
      editing_historical_application: "Editing historical application details is disabled",
      active_applications: "Active Applications",
      inactive_applications: "Inactive Applications",
      active_enrollments: "Active Enrollments",
      inactive_enrollments: "Inactive Enrollments",
      isRelevantForRenewal: "Program is set as relevant for renewal",
      isIrrelevantForRenewal: "Program is set as irrelevant for renewal",
      current_total_used_amount:
        "Current total used amount is <strong>${{usedAmount}}</strong> out of <strong>${{approvedAmount}}</strong>",
      delete_popover_text: "Delete Entry",
      amount: "Amount",
      fetch_patient_details_error: "Failed to fetch patient information for auto-filling the Express Enrollment form",
      fetch_questionnaire_error: "Failed to fetch questionnaire for the Express Enrollment form",
      additional_comments: "Additional Comments",
      additional_comments_length_error: "Maximum character limit reached. Your note must not exceed 20k characters.",
      tabs: {
        enroll: "Enroll",
        track: "Track",
        attachments: "Attachments",
        claim: "Claim",
        order: "Order"
      },
      claims_tab: {
        all_claims: "All Claims",
        pending_claims: "Pending Claims:",
        paid_claims: "Paid Claims:",
        no_claims_yet: "No claims yet!",
        no_claims_found: "No Claims Found!",
        once_the_application_is_approved: "Once the application is approved,",
        click_buttons_below: "Click the button below to create a new claim.",
        new_claim: "New Claim",
        edit_claim: "Edit Claim",
        summery_date_of_service: "Date of Service:",
        facility: "Facility:",
        no_facility: "No Facility",
        save_claim: "Save Claim",
        general: "General",
        claim_status: "Claim Status*",
        claim_date: "Claim Date*",
        follow_up_date: "Follow‒up Date",
        payable_to: "Payable to",
        notes: "Notes",
        attachments: "Attachments",
        added_note_by: "Added by {{user}} on {{addedDate}}",
        updated_note_by: "Updated by {{user}} on {{addedDate}}",
        upload_attachment_by: "Uploaded by {{user}} on {{addedDate}}",
        delete_claim_note: "Delete Claim note",
        delete_claim: "Delete Claim",
        duplicate_claim: "Duplicate Claim",
        do_you_want_duplicate_claim: "Do you want to duplicate the claim?",
        include_notes_and_attachments: "Include notes and attachments:",
        delete_claim_attachment: "Delete Claim Attachment",
        last_updated: "Last saved {{updatedDate}} ",
        by_user: " by {{user}}",
        are_you_want_delete_note: "Are you sure you want to delete this note?",
        are_you_want_delete_claim: "Are you sure you want to delete this claim?",
        are_you_want_delete_attachmente: "Are you sure you want to delete {{fileName}}?",
        medication_service_information: "Medication / Service Information",
        note_attachments: "Notes / Attachments",
        choose: "Choose",
        select_medication: "Select medication",
        enter_item: "Enter item",
        enter_code: "Enter code",
        at_least_one_item: "To save, enter at least one line item for this claim.",
        amount_required: "Amount Required",
        error_message: {
          get_claim_attachments: "failed to get claim attachments",
          attachment_upload_failed: "Attachment upload failed - file size exceeds {{maxSize}} max.\n Please try again.",
          get_claim_notes: "failed to get claim notes",
          failed_to_get_orders: "Failed to get orders",
          only_numbers_and_letters: "Numbers / letters only",
          only_numbers: "Numbers only"
        },
        popover: {
          pending: "Pending: ",
          pending_claim: "Claim has not yet been submitted to the program sponsor",
          submitted: "Submitted: ",
          submitted_claim: "Claim has been submitted to the program sponsor",
          paid: "Paid: ",
          paid_claim: "Full or partial payment has been received",
          denied: "Denied: ",
          denied_claim: "Claim was denied in full",
          medical: "Medical: ",
          medical_claim: "Typically administered at providers' office and billed through the patient’s medical benefit",
          pharmacy: "Pharmacy: ",
          pharmacy_claim:
            "For medications dispensed through pharmacy and billed through the patient’s pharmacy benefit",
          pending_payment: "Pending Payment: ",
          pending_payment_claim: "Claim was approved by the program sponsor and is now awaiting payment",
          oop: "OOP Max Met: ",
          oop_claim: "Patient met their OOP and no longer needs reimbursement from assistance program"
        },
        constant_lists: {
          pending: "Pending",
          submitted: "Submitted",
          paid: "Paid",
          denied: "Denied"
        },
        date_of_service_input_label: "Date of Service*",
        account_number: "Account Number",
        add_claim_note: "Add claim note",
        size_per_file: "15MB per file",
        upload_claim_attachment: "Upload claim attachment",
        type: "Type*",
        treatment_service: {
          text: "Medication / Service",
          pop_up_content:
            "For <bold>copay foundation</bold> applications, select the patient's <bold>relevant drugs</bold> from the <bold>Track</bold> tab when approving the application to see them here."
        },
        service_code: "Service Code",
        amount: "Amount*",
        total: "Total",
        add_new_item: "Add new item",
        date_submitted: "Submitted Date",
        payment_information: "Payment Information",
        paid: "Paid",
        claim_is_processing: "Claim is Processing....",
        please_wait_while_we_save_the_claim: "Please wait while we save the claim",
        denied: "Denied",
        patient_responsibility: "Patient Responsibility",
        pr: "PR",
        balance: "Balance",
        amount_greater_requested: "Amount entered is greater than the requested amount.",
        received_date: "Posted Date*",
        approved_date: "Approved Date",
        denied_date: "Denied Date*",
        payment_type: "Payment Type",
        select_payment_type: "Select payment type",
        check_number_ach: "Check number / ACH",
        enter_number: "Enter number",
        filter_by_claim_status: "Filter by claim status:",
        actions_menu: {
          edit: "Edit",
          duplicate: "Duplicate",
          delete: "Delete",
          restore: "Restore"
        },
        alert_max_character: "Maximum character limit reached. Your note must not exceed 250 characters.",
        add_note: "Save Note",
        enter: "Enter",
        to_manage_free_drug_orders: "To order free drugs for your patient, go to pharmacy.",
        go_to_pharmacy: "Go To Pharmacy",
        to_order_free_drug: "To order free drugs for your patient, use the link to go to pharmacy.",
        upgrade_required: "Upgrade required. Contact your customer success manager to get started.",
        no_orders_yet_once_the_application_is_approved:
          "No orders yet! Once the application is approved, go to pharmacy to manage free drug orders for your patient.",
        statuses: {
          titles: {
            order_required: "Order Required",
            in_transit: "In Transit",
            in_pharmacy: "In Pharmacy",
            drug_dispensed: "Drug Dispensed",
            drug_billed: "Drug Billed"
          },
          actions: {
            ordered: "Mark as Ordered",
            arrived: "Mark as Arrived",
            dispensed: "Mark as Dispensed",
            done: "Mark as Done"
          }
        }
      },
      enroll: {
        digital_signature: {
          enrollment_header: "Enrollment Form",
          incomplete_enrollment_description: "Click the open editor button to fill out the Enrollment Form.",
          open_editor: "Open Editor",
          delete_pdf: {
            no_signatures_title: "Delete PDF",
            no_signatures_message_content: "You are about to delete the pdf file.\n\nAre you sure you want to do this?",
            pending_signatures_title: "Delete PDF & revoke signatures Requests ",
            pending_signatures_message_content:
              "You are about to delete the pdf file.\nAll signatures pending or signed will be lost.\n\n\nAre you sure you want to do this?",
            with_signatures_message_title: "Delete signed PDF ",
            with_signatures_message_content:
              "You are about to delete the pdf file.\nAll signatures pending or signed will be lost.\n\n\nAre you sure you want to do this?",
            delete_error_message_pdf: "Enrollment form failed to delete.",
            delete_error_message_pending_signatures_pdf: "Enrollment form & signature request failed to delete.",
            delete_success_message_pdf: "Enrollment form deleted successfully",
            delete_success_message_pending_signatures_pdf: "Enrollment form & signature request deleted successfully"
          },
          last_changed: "Last changed on {{signatureSignDate}}",
          upload_pdf: "Upload PDF",
          upload_pdf_step_content:
            "Upload the completed pdf (not a scanned copy) to proceed to the digital signatures step, or alternatively skip and upload an already signed form for bookkeeping.",
          upload_to_esign: "Upload to SmartSign",
          requires_wet_signature:
            "This application requires wet signatures; digital signatures are not accepted by the sponsor.\n Optionally, you can upload the signed form for bookkeeping in TailorMed.",
          skip_and_upload_a_signed: "Skip & Upload a Signed Form",
          upload_a_signed: "Upload a Signed Form",
          upload_the_signed_form_for_bookkeeping:
            "Optionally, you can upload the signed form for bookkeeping in TailorMed.",
          alarm_message_cant_add_digital_signatures:
            "We can’t add digital signatures to scanned or unrecognized PDFs. To use SmartSign in step 3, delete the pdf and restart.",
          wet_signer_step_title: "Wet Signature",
          wet_signer_description:
            "To complete the enrollment form, This application requires wet signature from your {{signerType}}; digital signature are not accepted.",
          wet_signature_widget: {
            signed_by: "Wet signature was signed \n by {{signerType}} {{name}} on \n {{date}}",
            reupload_signed_form: "Re-upload a signed form",
            wet_signature_widget_initial_description: "Please print the PDF and upload it once it has been signed.",
            print: "Print",
            upload_signed_form: "Upload a signed form",
            reupload_modal_title: "Re-upload a signed form will Remove \nSignature From Form",
            reupload_modal_description:
              "The signature will be removed from the enrollment form. \n\n Are you sure you want to do this?"
          },
          enrollment_form_finished: "Enrollment Form Finished",
          please_download_or_print_the_pdf: "Please download or print the PDF to submit the signed Enrollment Form.",
          download: "Download",
          print: "Print",
          not_allowed_message: "{{actionName}} is not allowed: must be a Financial Navigation with phi access."
        },
        enrollment_form: {
          header: "Enrollment Form",
          headerForNovartis: "Enrollment Information",
          description: "Click the button to complete the Enrollment Form",
          descriptionForNovartis: "Click the open editor button to fill out the Enrollment Form",
          description_one_step: "Click the Prepare button to fill out and submit the Enrollment Form",
          edit_button: "Edit Form",
          delete_button: "Delete",
          prepare_button: "Prepare",
          open_editor_button: "Open Editor",
          continue_button: "Continue Editing",
          prepare_and_submit_button: "Prepare and Submit",
          edit_and_submit_again_button: "Edit and Submit Again",
          save_success_message: "Application form was saved successfully",
          save_draft_success_message: "Enrollment Form was saved to Draft",
          save_error_message: "There was an issue saving the application form",
          no_access_tooltip: "The user have no permission to see the data.",
          generate_success_message:
            "Enrollment Form was generated successfully. Next Step is available - Get E-Signatures",
          generate_error_message: "There was an issue generating the enrollment form",
          continue_editing: "Continue Editing"
        },
        electronic_signatures: {
          titles: {
            signature_type: "Signature Type",
            required_full_name: "Full Name *",
            required_send_by_email: "Send by Email *",
            send_by_sms: "Send by SMS",
            phone_number: "Phone Number",
            date: "Date",
            role: "Role",
            status: "Status"
          },
          genentech_free_drug: {
            description:
              "This application requires Prescriber’s Consent. Use the options on the right to upload<br/> a signature image (JPG or PNG), or to request a digital signature via DocuSign.",
            secondary_description:
              "Fill in the missing details to enable sending the cover letter to the prescriber via DocuSign to provide their electronic signature."
          },
          prescriber: "Prescriber",
          edit: "Edit",
          cancel_docusign: "Cancel Docusign",
          cancel_signature: "Cancel Signature",
          docusign: "DocuSign",
          patient_recipient_one: "Patient (Recipient 1)",
          physician_recipient_two: "Physician (Recipient 2)",
          physician: "Physician",
          sender: "Sender",
          header: "Signatures",
          description:
            "Send the filled Enrollment Form to the required parties for E - Signature or upload a signed PDF form",
          signature_sent: "Signature Request was sent to recipients on {{signatureSentDate}}",
          signature_signed: "Signature Request was signed on {{signatureSignDate}}",
          signature_uploaded: "Signature was uploaded on {{signatureSignDate}}",
          get_signatures_button: "Get E-Signatures",
          get_signature_button: "Get E-Signature",
          upload_signed_form_button: "Upload Signed Form",
          upload_signature_button: "Upload Signature",
          send_via_docusign_button: "Send Via DocuSign",
          secondary_description_bold: "Send for e-signature - ",
          secondary_description:
            "Fill in the missing details to enable sending signature request to the recipients by email & SMS",
          sender_header: "Sender",
          view_request_button: "View Request",
          close: "Close",
          email_to_recipient:
            "The email will arrive to recipient's mailbox and might be marked as spam.\nYou will receive an email once all recipients have signed",
          cancel: "Cancel",
          mark_as_signed: "Mark as signed",
          mark_as_signed_warning: "Mark as signed to submit the form",
          placeholders: {
            send_by_phone: "(000) 000-0000",
            email: "name@gmail.com",
            full_name: "Enter Full Name"
          },
          status: {
            unsigned: "Unsigned",
            pending: "Pending Signatures",
            signed: "Signed",
            draft: "Draft"
          },
          success: "The signature request was sent successfully",
          tooltip:
            "The Sender details are mandatory\nEmails will be used for notification after signature completion\nPhone will be presented to the patient as contact details.",
          modal: {
            cancel_docusign_title: "Cancel DocuSign",
            cancel_docusign_description_sign:
              "This action will reset the consent request.\nAll signatures pending or signed will be lost.\n\n\nAre you sure you want to do this?",
            cancel_request_title: "Cancel Signature Request",
            cancel_request_description_before_sign:
              "You are about to cancel the signature request.\nThis action will notify the recipients by email. The PDF will not be available for signatures anymore.\n\nAre you sure this is what you want to do?",
            cancel_request_description_after_sign:
              "You are about to cancel the signature request.\nSince the document was signed by all recipients, a notification will NOT be sent.\n\nAre you sure this is what you want to do?",
            cancel_signature_title: "Cancel Signature",
            cancel_signature_description:
              "You are about to cancel the current signature.\n\nAre you sure this is what you want to do?",
            delete_signature_title: "Delete Signature",
            delete_signature_description:
              "You are about to delete the current signature.\n\nAre you sure this is what you want to do?",
            yes_sure: "Yes, I'm Sure",
            cancel: "No",
            delete_form_title: "Delete Enrollment Form",
            reset_form_tile: "Reset Form",
            cancel_form_title: "Cancel Signature",
            delete_form_description:
              "You are about to delete the enrollment form.\n- This action will initialize the entire enrollment process.\n- The data entered in the enrollment form will be saved and pre-populated the next time you prepare the form.\n\nAre you sure this is what you want to do?",
            reset_form_description:
              "This action will reset the patient consent.\nAll signatures pending or signed will be lost.\n\n\n\nAre you sure you want to do this?",
            close_form_title: "Close Enrollment Form",
            close_form_description:
              "You are about to close the enrollment form - the data entered in the enrollment form will not be saved.\n\n\nAre you sure this is what you want to do?"
          },
          errors: {
            send_signature_error: "There was an issue sending the signature request",
            send_signature_phone_error:
              "The DocuSign request can't be sent to {{phoneNumber}} due to lack of SMS consent. Please use Email or update the contact number.",
            same_emails_error: "Use a different email",
            upload_error: "File upload failed. Please try again.",
            send_signature_invalid_phone_error:
              "There was an issue sending the signature request - phone number {{phoneNumber}} is invalid."
          },
          signature_step: {
            signers: {
              patient: "Patient",
              physician: "Physician",
              hcp: "HCP",
              caregiver: "Patient / Caregiver"
            },
            status: {
              pending: "Pending Signature",
              signed: "Signed",
              expired: "Link Expired",
              not_started: "Not Started"
            },
            signature_options: {
              fill_now: "Fill Now",
              request_signature: "Request Signature"
            },
            request_signature_data: { email: "Email", sms: "SMS" },
            action_buttons: {
              remove: "Remove",
              cancel: "Cancel",
              submit: "Submit",
              save: "Save",
              request: "Request",
              resend_request: "Resend",
              send_reminder: "Send Reminder",
              revoke: "Revoke",
              upload_image: "Upload Image",
              revoke_modal_title: "Revoke Signature Request",
              remove_modal_title: "Remove Signature From Form",
              revoke_modal_description:
                "You are about to revoke the signature request.\nThe link sent to the signee will expire.\n\n Are you sure you want to do this?",
              remove_modal_description:
                "The signature will be removed from the enrollment form.\n\n Are you sure you want to do this?",
              draw: "Draw"
            },
            messages: {
              expiration_warning_days: "Request expires automatically in {{days}} {{dayLabel}}.",
              expiration_warning_hours: "Request expires automatically in {{remaining}} hours.",
              expiration_warning_days_hours:
                "Request expires automatically in {{remaining}} {{days}} {{dayLabel}} and {{hours}} {{hourLabel}}.",
              expiration_warning_one_hour: "Request expires automatically in less than one hour.",
              send_request_email: "Please send signature request via email.",
              send_request_sms_and_email: "Please send signature request via email and/or SMS.",
              please_verify_email: "Please verify that the email address is correct.",
              missing_dob: "Missing Patient Date of Birth. DOB is used to authenticate the patient when signing. ",
              missing_caregiver_name: "Missing information. Please add Caregiver name. ",
              missing_patient_name: "Missing information. Please add Patient name. ",
              missing_email_and_phone: "Missing information. Please add email and phone number. ",
              missing_email: "Missing information. Please add email. ",
              missing_phone: "Missing information. Please add phone number. ",
              patient_data_changed:
                "Please note that the patient details have been updated. You can revoke the signature and request it again. ",
              please_verify_details: "Please verify that the details are correct. ",
              missing_info_link: "<br/>Please add in <a>Manage Patient Details</a> section.",
              signature_revoked_success: "Signature was revoked successfully",
              signature_removed_success: "Signature was removed successfully",
              signature_revoked_error: "Failed to revoke signature",
              signature_revoke_signed_error: "The revoke action failed because the PDF has already been signed.",
              signature_send_reminder_signed_error:
                "The send reminder action failed because the PDF has already been signed.",
              signature_remove_error: "Failed to remove signature",
              request_email_bounce_error:
                "The email has bounced, please check the details and request again. If the problem persists please contact support",
              success: {
                request: "Request was sent successfully",
                reminder: "Reminder was sent successfully",
                fill_now_success: " Signature was populated in the application PDF"
              },
              errors: {
                general_error: "Something went wrong. Please try again.",
                technical_error:
                  "Something went wrong due to technical reasons.\nplease check the details and request again. If the problem persists please contact support.",
                request: {
                  sms: "Email was sent successfully. SMS has failed to send. You can continue using email as usual.",
                  email: "SMS was sent successfully. Email has failed to send. You can continue using SMS as usual."
                },
                single_channel_request: {
                  email:
                    "Email has failed to send. Please check the email address and request again. If the problem persists please contact support.",
                  sms: "SMS has failed to send. Please try again. If the problem persists please contact support."
                },
                reminder: {
                  sms: "Email reminder was sent successfully. SMS reminder has failed to send. You can continue using email as usual.",
                  email:
                    "SMS reminder was sent successfully. Email reminder has failed to send. You can continue using SMS as usual."
                },
                fill_now_draw_failed: "Add signature failed. Please try again.",
                fill_now_upload_failed: "Attachment upload failed. Please try again.",
                fill_now_image_size_error:
                  "Attachment upload failed - file size exceeds {{maxSize}} MB max. \nPlease try again."
              }
            },
            caregiver: "Caregiver",
            caregiver_name: "Caregiver Name",
            patient_name: "Patient Name",
            or: "OR",
            singed_info: "Signed Info",
            sent_on: "Sent on {{date}}",
            signed_by: "Signed by {{name}} on {{date}}",
            fill_by_draw: "Signature was drawn by {{name}} on {{date}}",
            fill_by_upload: "{{signerType}} signature image was uploaded by {{name}} on {{date}}",
            fill_by_upload_signer_type: {
              patient: "Patient's",
              physician: "Physician's",
              hcp: "HCP's",
              caregiver: "Caregiver’s"
            },
            reminded_at: "Reminder sent on {{date}}",
            fill_now_upload: "Upload a jpg/png image of the {{title}}’s {{name}} signature",
            fill_now_draw: "If authorized, draw your signature with the cursor",
            smart_sign: "SmartSign",
            allSignatures: "Signatures",
            description_hcp:
              "To complete the enrollment form, this application requires a Health Care Professional (HCP) signature",
            description_physician:
              "To complete the enrollment form, this application requires the patient's physician signature.",
            description_caregiver:
              "To complete the enrollment form, this application requires the patient's or caregiver signature.",
            description_patient: "To complete the enrollment form, this application requires the patient's signature.",
            description_hcp_with_caregiver:
              "To complete the enrollment form, this application requires a Health Care Professional (HCP) and the patient's or caregiver signature.",
            description_hcp_with_patient:
              "To complete the enrollment form, this application requires a Health Care Professional (HCP) and the patient's signature.",
            description_physician_with_caregiver:
              "To complete the enrollment form, this application requires the patient's physician and the patient's or caregiver signature.",
            description_physician_with_patient:
              "To complete the enrollment form, this application requires the patient's physician and the patient's signature.",
            description_instruction:
              "<br/>Use the options below to add or request digital signatures, or skip & upload a signed form instead.",
            novartis_copay_description_patient: "This application requires the patient's consent.",
            signature_pad_title: "Draw your signature",
            signature_pad_clear: "Clear",
            signature_pad_button_text: "Add To Form",
            signature_pad_modal_title: "Add Signature",
            signature_pad_not_clear_error: "Signature not clear enough. Please try again",
            signature_pad_modal_description:
              "Draw your signature in the designated area below. Adding the signature will \n automatically insert it into the enrollment form."
          }
        },
        application_form_submission: {
          eagle_pemfexy: {
            description: "Send the signed Enrollment Form and attachments by eFax to the manufacturer",
            submit_button: "Submit with eFax",
            submit_success_message: "Submission using eFax is in process for TM-{{patientId}}.",
            submit_in_progress:
              "Application was submitted to the manufacturer on {{submitDate}}. <strong>Submission using eFax is in process.</strong><br/>Once a response is received you will get an email."
          },
          novartis: {
            description: "Submit enrollment to the manufacturer"
          },
          genentech_free_drug: {
            description:
              "Click Submit to send the application to the manufacturer. <br>Fax any supporting documents, prescriptions, or additional details to Genentech at (833) 999-4363",
            submit_completed:
              "The application was submitted to the Manufacturer on {{submitDate}}. <br>Fax any supporting documents, prescriptions, or additional details to Genentech at (833) 999-4363"
          },
          header: "Enrollment Form Submission",
          description: "Send the signed Enrollment Form and attachments to the manufacturer",
          submit_button: "Submit",
          close_button: "Close",
          submit_in_progress:
            "Enrollment Form was submitted on {{submitDate}}. Once a response is received you will get an email.",
          submit_completed: "Application was submitted to the manufacturer on {{submitDate}}.",
          submit_completed_novartis: "Enrollment form was submitted on {{submitDate}}.",
          submit_succeeded_without_date:
            "Enrollment Form was submitted. Once a response is received you will get an email.",
          submit_failed_with_date:
            "Application was submitted to the manufacturer on {{submitDate}}. <strong>Submission failed, please try again.</strong>",
          submit_succeeded_automatic: "Enrollment Form was submitted on {{submitDate}}.",
          submit_succeeded_without_date_automatic: "Enrollment Form was submitted.",
          view_details: "View Details",
          submit_success_message: "Application was submitted successfully.",
          submit_modal: {
            title: "Submit",
            description:
              "You are about to submit the application. Please make sure all the relevant documents are attached",
            description1: "You are about to submit the application.",
            application_form_divider: "Application Form",
            files_size_exceed_max:
              "The total size of all files should not exceed {{maxSize}}. Please check the 'Attachment' tab and update the files as needed.",
            attachments_divider: "Additional Attachments",
            attachments_divider_brackets: "(supporting PDF format only)",
            cancel_button: "Cancel",
            generic_error_message: "Enrollment Form Submission failed, Please try again.",
            attachments_error_message: "Loading Attachments failed, Please try again",
            remove_attachment_tooltip: "Remove from application submit",
            steps_information:
              "<div>PLEASE NOTE: An eSigned Prescriber Foundation Form cannot be used as a prescription for upfront shipments. To submit a valid prescription you can: <br>Provide a verbal prescription to our specialty pharmacy by calling (833) 888-4363, Fax a prescription with a “wet” signature to (833) 999-4363 <br>Submit an ePrescription to: MedVantx (AmeriPharm), NCPDP/NABP: 4351968, NPI: 1073692745, If you have questions please contact Access Solutions at (866) 422-2377.</div>",
            submit_information:
              "<div><p>A valid prescription must be received before a determination of assistance can be made as this is a requirement for program enrollment.</p><p>An e-signed enrollment form is <bold>not a valid prescription.</bold> In order for your patient to be considered for assistance, <bold>a valid prescription is required and must be submitted at the time of enrollment request</bold> in one of two ways:</p><p>Option 1: Submit a paper prescription with an ink signature, either by faxing it to 1-877-736-6506 or by attaching a scanned copy to the application.</p><p>Option 2: E-prescribe to AmeriPharm (NPI number–1073692745; NCPDP number–4351968) or MedVantx (NPI number–1235371535; NCPDP number– 4354180). <bold>New York prescribers must e-prescribe.</bold></p><span>"
          },
          view_submitted_modal: {
            title: "View Submitted Application  ",
            description: "The application form includes the enrollment form & all attachments",
            close: "Close"
          },
          fetching_application_form_error_after_submit:
            "Successfully submitted Enrollment Form. Failed to update Application Form information",
          fetching_application_form_error: "Failed to update Application Form information",
          fetching_express_enrollment_error_after_submit:
            "Successfully submitted Enrollment Form. Failed to update Enrollment Form Submission information",
          fetching_application_error_after_submit:
            "Successfully submitted Enrollment Form. Failed to update Application information"
        },
        enroll_result: {
          download_saving_card: "Download Savings Card",
          application_approved_for: "Application Submitted And Approved\nFor {{items}}",
          application_denied_for: "Application Submitted And Was Denied\nFor {{items}}",
          application_failed_for: "Application Submission Failed For Drug {{items}}",
          contact_genentech_error: "<span>Please contact Genentech’s Helpdesk at 855-692-6729.<span>",
          admin_only: "Admin {{drug}}",
          drug_and_administration: "{{drug}} (Drug and Administration)",
          drug: "Drug {{drug}}",
          drugs: "Drugs {{drugs}}",
          application_approved_subtitle:
            "In the next few days, a welcome letter will be mailed to the patient’s address provided under the “Patient” section. The letter has information about the program and instructions for how to use it.",
          application_approved_assign_debitcard_text:
            "You can retrieve the 16-digit virtual card number from the program portal or by calling {{phone}}",
          application_denied_subtitle: "<span><bold> Denial reason: </bold> {{denialReason}}<span>",
          unknown_denial_reason: "Unknown reason.",
          patient_status: "Patient Status: ",
          member_id: "Member ID: ",
          enrolled_programs: "Enrolled Program(s): ",
          rxbin: "RxBIN: ",
          group_number: "Group Number: ",
          pcn: "PCN: ",
          application_id: "TailorMed Application ID: ",
          card_activation_date: "Card Activation Date: ",
          card_end_date: "Card End Date: ",
          bin: "BIN: ",
          total_grant_amount: "Total Grant Amount: ",
          details_saved: 'All details are saved and can be viewed on the application’s "Track" tab.',
          call_support: "If you have any questions, please call {{phone}}.",
          contact_support: "If technical support is needed - please contact ",
          contact_support_tech: "If technical help is required - please contact ",
          log_reason: "Please don’t forget to include the above failure log reason.",
          contact_support_with_error: "Contact Support at ",
          dont_forget_to_include_error: ". Please don’t forget to include the above failure reason.",
          failed_to_get_submit_result: "Failed to Get Submit Result",
          failure_reason: "Failure reason: ",
          practice_not_registered:
            "The practice's NPI information is not registered on the Pfizer Co-pay portal. Please confirm the NPI details and resubmit. For further assistance, call Pfizer Patient Support at 800-555-4820 if needed.",
          patient_is_already_enrolled:
            "The patient you are trying to enroll is already enrolled into the program. For assistance, please contact Pfizer Patient Support at 800-555-4820.",
          patient_has_government_insurance:
            "Enrollment is not possible for this patient due to government insurance eligibility. Please verify the insurance details and attempt to resubmit the enrollment.",
          request_id: "Request ID: ",
          npi_associated_to_portal: "NPI Associated to Portal: ",
          please_try_to_resubmit_the_application: "Please try to resubmit the application.",
          track_tab_info: 'All details are saved and can be viewed on the application\'s "Track" tab',
          application_success: "Application Submitted and Approved for ",
          application_denial: "Application Denied for ",
          application_error: "Application Submission Failed for {{item}}",
          application_error_multi: "Application Submission Failed for {{item1}} and {{item2}}",
          denial_reason: "Denial reason: ",
          group: "Group: ",
          pfizer_copay_oral_failure_massage: "Please contact the Help Desk TFN line 1-800-337-9539"
        },
        editFileWarningModal: {
          header: "Save & Download will Create New Enrollment Form",
          body: "You are about to edit the pdf file. <br> <ul> <li>All signatures pending or signed will be lost.</li> <li>However, any data previously edited in the enrollment editor will be saved in the editor, and pre-populated the next time you generate the pdf.</li> </ul><br> Are you sure you want to do this?"
        },
        unsupportedPDFModal: {
          header: "Unsupported PDF Uploaded",
          body: "We are unable to digitally sign scanned or unrecognized PDFs.\n Please upload a non-scanned PDF for digital signature. Alternatively, you can skip this step and upload a signed document, scanned or not.",
          close: "Close"
        },
        uploadToSignWarning: {
          header: "Upload to SmartSign",
          body: "Uploading form will:<br> <ul> <li>Override the pdf </li> <li>All signatures pending or signed will be lost </li></ul><br> Are you sure you want to do this?"
        },
        skipAndUploadSignedWarning: {
          header: "Skip & Upload a Signed Form",
          body: "Uploading form will:<br> <ul> <li>Override the pdf </li> <li>All signatures pending or signed will be lost </li><li>Disable the option to add digital signatures</li></ul><br> Are you sure you want to do this?"
        },
        applicationStatusChangeWanning: {
          header: "Change in Status",
          body: "There are signature requests pending, changing the status will revoke them.<br> <br> Are you sure you want to proceed?"
        }
      },
      used_amount_modal: {
        delete_confirm_modal: {
          title: "Delete Used Amount",
          content: "This action will delete the Used Amount - Are you sure you want to proceed ?"
        },
        add_entry: "+ Add Entry",
        failedToUpdateMessage: "Failed to update Used Amount rows",
        updatedSuccessfullyMessage: "Used amounts we're updated successfully",
        usedAmountExceededMessage: "Exceeding approved amount",
        missingUsedAmountText: "Missing Used Amount",
        missingDateText: "Missing Date"
      },
      applicationsTable: {
        name: "Name",
        id: "Id",
        status: "Status",
        approved: "Approved",
        valid_through: "Valid Through",
        last_updated: "Last Updated",
        renewal_actions: "Renewal Actions",
        claim_amounts: "Claim Amounts",
        claim_actions: "Claim Actions",
        total_paid_pending_amount: "Total Paid Amount | Pending Amount",
        total_paid_amount: "Total Paid Amount: ",
        sum_of_all_paid_amounts: "Sum of all paid amounts for claims received",
        pending_amount: "Pending Amount: ",
        sum_of_all_requested_amounts: "Sum of all requested amounts for pending, submitted and pending payment claims",
        add_new_claim: "Add new claim",
        actions: {
          renew: "Renew application",
          setAsIrrelevant: "Set as irrelevant for renewal",
          setAsRelevant: "Set as relevant for renewal",
          delete: "Delete",
          set_irrelevant_for_renewal_popup_header: "Removing from Renewal List",
          set_irrelevant_for_renewal_popup_content:
            'You are about to set "{{applicationName}}" as irrelevant for renewal for this patient.',
          set_relevant_for_renewal_popup_header: "Set application as relevant",
          set_relevant_for_renewal_popup_content:
            'Are you sure you want to set "{{applicationName}}" as relevant for renewal for this patient?\nIt will be tracked in "Pending Renewal" list, when relevant.',
          delete_application_content:
            "You are about to permanently delete the  application for {{applicationName}} and all its associated information and attachments.\n\n",
          delete_application_title: "Application Deletion",
          disable_delete_application_internal_approved:
            "This application is managed in Funds Management and can’t be deleted.",
          disable_delete_application_used_amount: "The application has reported used amount, so it can not be deleted.",
          disable_delete_application_reported_claims:
            "This application has reported claims and cannot be deleted. Remove any associated claims and try again.",
          disable_delete_application_pharmacy_patient:
            "This application has an associated pharmacy patient and cannot be deleted.",
          this_application_cannot_be_deleted: "This application cannot be deleted."
        }
      },
      autoFillModal: {
        title: "Configure Auto-Filled Application Form",
        subHeaderText: "Please select specific configuration for the below form fields:",
        cancel: "Cancel",
        save_and_download: "Save & Download",
        patientUninsured: "Patient is uninsured",
        selectNameInsteadOfFN: "Use selected physician name instead of FN team name for shipping details",
        editPhysician: "Edit Physician information",
        closePhysician: "Close Physician information",
        editFacility: "Edit Facility information",
        closeFacility: "Close Facility information",
        editRxInsurance: "Edit RX insurance",
        closeRxInsurance: "Close RX insurance",
        pharmacy_benefit_insurance: "Pharmacy Benefit Insurance Exists?",
        insurance_part_D: "Is this a Part D plan?",
        placeholder: {
          shipmentRecipient: "Shipment / recipient information",
          physicianInformation: "Physician information",
          facilityInformation: "Facility information",
          diagnosisInformation: "Diagnosis information",
          primaryCoverage: "Primary coverage",
          secondaryCoverage: "Secondary coverage",
          associatedDrug: "Associated drug",
          selectPhysician: "Select a physician",
          selectFNTeam: "Select FN team",
          name: "Name",
          firstName: "First Name",
          middleName: "Middle Initial",
          lastName: "Last Name",
          dob: "Date of Birth",
          gender: "Gender",
          gender_female: "Female",
          gender_male: "Male",
          street: "Street",
          address: "Address",
          suiteNumber: "Suite Number",
          city: "City",
          state: "State",
          zip: "Zip",
          phone: "Phone",
          fax: "Fax",
          facility: "Facility",
          planName: "{{coverageLevel}} Plan Name",
          planMarket: "{{coverageLevel}} Plan Market",
          memberId: "Member ID {{coverageLevel}}",
          ICD: "ICD",
          npi: "NPI",
          dea: "DEA",
          taxId: "TAX ID",
          seeDetails: "See Details",
          seeLess: "See Less",
          applicationCreated: "New application has been created",
          contactName: "Contact Name",
          contactEmail: "Contact Email",
          siteOfCareName: "Site of Care Name",
          RxCoverage: "Rx coverage",
          RxPayerName: "Payer Name",
          RxPlanMarket: "Plan Market",
          RxMemberId: "Member ID",
          RxGroup: "RxGRP",
          RxBIN: "RxBIN",
          RxPCN: "RxPCN"
        },
        required_message: "This is required"
      },
      all_statuses: "All Statuses",
      active_statuses: "Active Statuses",
      inactive_statuses: "Inactive Statuses",
      attachments: {
        wrong_file_type: `Attachment upload failed - Only {{fileTypes}} files are allowed,\nPlease try again.`,
        exceeds_total_file_size:
          "Attachment upload failed - The total size of all files should not exceed {{maxSize}} MB.",
        only_pdf_files: "Only PDF files are allowed.",
        exceeds_file_size: `Attachment upload failed - File size exceeds {{maxSize}} MB max,\nPlease try again.`,
        delete_modal: {
          cancel: "Back",
          submit: "Yes, I'm sure",
          title: "Delete Attachment",
          first_content: "You are about to delete an attachment.",
          second_content: "Are you sure this is what you want to do?"
        },
        upload_file: "Upload File",
        upload_pdf_file: "Upload PDF File",
        application_attachments: "Enrollment Attachments",
        format_requirements: "Format requirements:",
        must_be_pdf: "Must be PDF",
        total_size_notice: "The total size of all files should not exceed {{maxSize}} ",
        no_attachments: "No attachments yet",
        upload: "Upload",
        delete: "Delete",
        uploaded_at: "Uploaded at",
        alt: {
          no_attachments: "No attachments yet"
        },
        error: {
          delete: "File delete failed",
          download: "File download failed",
          upload_generic: "File upload failed. Please try again.",
          upload_server: "File upload failed: {{errorMessage}}. Please try again.",
          cancel_request: "The E-Signature process failed to cancel the request",
          cancel_signature: "failed to cancel Signature"
        },
        success: {
          delete: "File has been successfully deleted",
          download: "File has been successfully downloaded",
          upload: "File upload successful.",
          cancel_request: "The E-Signature request was canceled successfully",
          cancel_signature: "The Signature was canceled successfully"
        },
        express_enroll: {
          no_attachments: {
            title: "Enrollment Attachments",
            sub_title:
              "Please add supporting attachments to the enrollment (only PDF format). Relevant attachments are:",
            content_first_line: "- Copies of the front and back of the patients medical and pharmacy insurance cards",
            federal_tax_Return: "- Most recent federal tax return (Page 1 of IRS 1040 form)",
            w_two_form: "- W-2 form",
            prescription_optional: "- Prescription (optional)",
            tooltip_form_instruction: "link for more information:",
            prescription_tooltip:
              "<span>An e-signed enrollment form is <bold>not a valid prescription.</bold> In order for your patient to be considered for assistance, <bold>a valid prescription is required and must be submitted at the time of enrollment request</bold> in one of two ways:<br><br>Option 1: Submit a paper prescription with an ink signature, either by faxing it to 1-877-736-6506 or by attaching a scanned copy to the application.<br><br>Option 2: E-prescribe to AmeriPharm (NPI number–1073692745; NCPDP number–4351968) or MedVantx (NPI number–1235371535; NCPDP number– 4354180). <bold>New York prescribers must e-prescribe.</bold><span>",
            footer:
              "* If you do not have the documents now, you will have the opportunity to submit them to the enrollment prior to submission.",
            eagle: {
              sub_title: "Please add supporting attachments to the enrollment (only pdf format).",
              sub_title_line_one: "Relevant attachments can include:",
              content_first_line: "- Copies of the front and back of patient’s medical insurance cards ",
              content_second_line: "- Copies of the front and back of patient’s pharmacy insurance cards "
            }
          }
        }
      },
      applications_in_hidden_programs: "Application/s exists in hidden programs",
      copy_paste_popover: {
        save_and_copy_to_clipboard: "Save & Copy To Clipboard",
        copy_to_clipboard: "Copy To Clipboard",
        popover_description:
          "Fill in the missing information and click copy. The information will be saved to your clipboard.",
        non_profit_Verification: "Non-Profit Verification",
        copay_assistance_cards_verification: "Co-pay Assistance Cards Verification",
        free_drug_assistance_verification: "Free-Drug Assistance Verification",
        fields: {
          medication_name: "Medication Name",
          name_of_non_profit: "Name of Non-Profit",
          copay_assistance_name: "Co-Pay Assistance Name",
          name_of_free_drug_program: "Name of Free Drug Program",
          phone_number: "Phone Number",
          non_profit_phone_number: "Non-Profit phone number",
          name_of_person_you_spoke_to: "Name of person you spoke to",
          verified_with: "Verified with",
          assistance_start_date: "Start date of the assistance",
          application_id: "Application ID",
          patient_id: "Patient ID",
          policy_number: "Policy Number",
          group: "Group #",
          group_number: "Group Number",
          bin: "BIN",
          pcn: "PCN",
          grant_amount: "Grant amount",
          patient_responsibility: "Patient Responsibility",
          grant_start_date: "Start date of Grant",
          grant_end_date: "Expiration of Grant",
          medication_listed_for_grant: "Medication listed for Grant",
          diagnosis_for_grant: "Diagnosis for Grant",
          member_id: "Member ID",
          approved_amount: "Approved Amount",
          approved_date: "Approved date",
          valid_through_date: "Valid through date",
          valid_through: "Valid through",
          additional_comments: "Additional Comments",
          out_of_pocket_amount: "Out of Pocket Amount",
          benefits_out_of_pocket_amount: "Benefits-Out of Pocket Amount",
          day_supply: "Day Supply",
          total_benefit_max: "Total benefit max",
          coverage_period: "Coverage Period"
        }
      },
      search_diagnosis: "Search diagnosis",
      search_diagnosis_error: "Error while searching diagnosis"
    },
    notifications: {
      label: "Notifications",
      title: {
        display: "Display",
        order_by: "Order by"
      },
      display_filter: {
        all_programs: "All Programs",
        open_with_relevant_patients: "Open with relevant patients",
        unread_notifications: "Unread notifications",
        recently_opened_programs: "Recently opened programs",
        closed_programs: "Closed programs"
      },
      order_sort: {
        date_new_to_old: "Date - new to old",
        date_old_to_new: "Date - old to new",
        status_open_to_close: "Status - open to close",
        unread_first: "Unread first"
      },
      program_value_and_moment: "Program {{value}}: {{moment}}",
      enrollment_closed: "Program enrollment period has closed",
      no_relevant_patients_waitlist_only: "Currently no relevant patients found that are applied to waitlist",
      no_relevant_patients: "Currently no relevant patients found",
      program_closed: "Program Closed",
      program_open: "Program Open",
      view_relevant_patients: "View Relevant Patients",
      get_notifications_failed: "Get notifications failed",
      marking_notifications_failed: "Marking notifications failed",
      relevant_patients_for: "Relevant patients for",
      back: "Back",
      mark_as_read: "Mark as {{read}}",
      results_unread: "{{results}} Results, {{unread}} Unread",
      tooltip: "Notifications"
    },
    top_opportunities: {
      relevant_patients_for_assistance_program_name: "Relevant patients for {{assistanceProgramName}}",
      back: "Back",
      program_status_time: "Program {{status}}: {{time}}",
      tm_patient: "TM-{{patientID}}",
      record_mrn: "MRN-{{mrnRecord}}",
      show_patients_from: "Show patients from",
      filter_strip: {
        all_assignees: "All Assignees",
        all_facilities: "All Facilities",
        all_physicians: "All Physicians",
        all_diagnoses: "All Diagnoses",
        all_diagnoses_codes: "All Diagnoses Codes",
        search_by_diagnosis_code: "Search by diagnosis code",
        search_by_diagnosis_name: "Search by diagnosis name",
        statuses: "Statuses",
        awaiting_assistance: "Awaiting Assistance"
      },
      failed_to_get_notification_data: "Failed to get notification data",
      received_corrupted_notification_data: "Received corrupted notification data",
      no_patients_found: "No Patients Found"
    },
    filter_list_view: {
      filter_list_top_section: {
        saved_lists: "Saved Lists",
        details: "Details",
        description: "From {{view}} • By {{user}}",
        unsaved_changes: "Unsaved Changes -",
        save: "Save",
        last_modified_lbl: "Last Modified: "
      }
    },
    savedList: {
      search_placeholder: "Search by list name",
      no_list_name: "List",
      description: "From {{view}} • By {{user}}",
      list_name_private_title: "Private List",
      list_name_private_sub_title: "This list will only be available to you.",
      copy_action: "The List link was successfully copied to your clipboard",
      sort_tooltip: "Sort",
      owner_in_valid_list: "This saved list is not available due to removed filters. Please delete and recreate.",
      non_owner_in_valid_list: "This saved list is not available due to removed filters - please recreate the list.",
      types_options: {
        all: "All Lists",
        private: "Private Lists",
        favorite: "Favorite Lists"
      },
      actions_menu: {
        edit: "Edit List Info",
        copy: "Copy List Link",
        delete: "Delete"
      },
      confirm_modal: {
        title: "Delete List",
        content:
          "Are you sure you want to delete ‘{{listName}}’ list? No one will be able to access this list anymore.",
        cancel_text: "Cancel",
        ok_text: "Yes, Delete",
        success_message: "List was successfully deleted",
        error_message: "Could not delete list"
      },
      discard_modal: {
        title: "Unsaved Changes",
        ok_text: "Yes, Discard",
        cancel_text: "Back",
        content: "Are you sure you want to discard the changes you made?"
      },
      show_number_of_records: "Show number of records",
      more_than_10000: "More than 10000 records",
      no_records: "No Records",
      get_number_of_records_failed: "Get number of patients failed!",
      sort_ascend_tooltip: "Click to sort ascending",
      sort_descend_tooltip: "Click to sort descending",
      sort_cancel_tooltip: "Click to cancel sorting"
    },
    contact_support: {
      title: "Contact Support",
      ticket_send_successfully: "Ticket was sent successfully",
      failed_to_send_ticket: "Failed to send message to support"
    },
    profile: {
      tooltip: "Profile and Settings"
    },
    calculator: {
      title: "Potential Savings Calculator",
      manufacturerTitle: "Manufacturer",
      manufacturerImport: "Input Manufacturer Name",
      drugNameTitle: "Drug Name & Package Type",
      drugNameInput: "Input Drug Name",
      packageSizeTitle: "Quantity / Package size",
      packageSizeQuantity: "Select quantity",
      packageNumberTitle: "Number of Packages",
      packageNumberInput: "Enter number",
      packageNumberTip: "Note - the number of packages will not affect the Pharmacy drug order",
      totalDrugPrice: "TOTAL DRUG PRICE",
      perPackage: "per package",
      input3Chars: "Please type at least 3 characters",
      loading_drug_information: "Loading drug information",
      total_price_error: "Total price cannot exceed 7 digits - please update the number of packages."
    },
    patient_statuses: {
      in_analysis: "In Analysis"
    },
    error_fallback: {
      title: "Unexpected Error",
      ok_text: "Go Back",
      p1: "Uh oh! Something went wrong on our end.",
      p2: "Click the button below to go back to the previous page."
    },
    express_enroll: {
      supports_express_enroll: "Supports Express Enrollment",
      supports_express_enroll_novartis: "Sponsored Content"
    },
    application_form_editor: ApplicationFormEditor,
    online_wizard: {
      title: "Online Wizard",
      subtitle: "An internal tool is used to preview and export EE forms.",
      unset_full_line_field_tooltip: "Click to unset field as full line.",
      set_full_line_field_tooltip: "Click to set field as full line."
    }
  }
};
