import { useSelector } from "react-redux";
import { selectUser } from "../../../../../store/selector";
import { formatName } from "../../../../../utils/user";
import { OTHER, PEMFEXY_DEFAULT_NDC } from "../../constants";

export const getEgalePemfexyFormInitialValues = ({ applicationForm }) => {
  const user = useSelector(selectUser);

  const insurance = applicationForm?.formData?.insurance;
  const facility = applicationForm?.formData?.facility;
  const { firstName, middleName, lastName } = applicationForm?.formData?.patient || "";

  const patientState =
    applicationForm?.formData?.patient?.state === "" ? null : applicationForm?.formData?.patient?.state;

  const providerId =
    !applicationForm?.formData?.provider?.id &&
    (applicationForm?.formData?.provider?.firstName || applicationForm?.formData?.provider?.lastName)
      ? OTHER
      : applicationForm?.formData?.provider?.id;

  const facilityId = !facility?.id && facility?.name ? OTHER : facility?.id;

  const isPatientCoverageUninsured =
    Object.values(insurance?.primary || []).length === 0 && Object.values(insurance?.secondary || []).length === 0;

  const policyholderName = `${firstName} ${middleName ? middleName : ""} ${lastName}`;

  const DEFAULT_PHONE_TYPE = "mobile";
  const DEFAULT_HOME_TYPE = "home";

  const initialValues = {
    ...applicationForm?.formData,
    patient: {
      ...applicationForm?.formData?.patient,
      state: patientState,
      phoneType: applicationForm?.formData?.patient?.phoneType || DEFAULT_PHONE_TYPE,
      homePhoneType: applicationForm?.formData?.patient?.homePhoneType || DEFAULT_HOME_TYPE,
      caregiver: {
        ...applicationForm?.formData?.patient?.caregiver,
        phoneType: applicationForm?.formData?.patient?.caregiver?.phoneType || DEFAULT_HOME_TYPE
      }
    },
    prescription: {},
    insurance: {
      ...insurance,
      patientCoverageUninsured: isPatientCoverageUninsured,
      secondary: {
        ...insurance?.secondary,
        policyholderSameAsPatient: insurance?.secondary?.name ? true : false,
        policyholderName
      },
      primary: {
        ...insurance?.primary,
        policyholderName
      }
    },
    provider: {
      ...applicationForm?.formData?.provider,
      id: providerId,
      lastName: applicationForm?.formData?.provider?.lastName || applicationForm?.formData?.provider?.firstName
    },
    facility: {
      ...facility,
      id: facilityId,
      contactName: formatName(user),
      contact: {
        ...facility?.contact,
        email: user.email
      }
    },
    clinical: {
      ...applicationForm?.formData?.clinical,
      productNdc: applicationForm?.formData?.clinical?.productNdc || PEMFEXY_DEFAULT_NDC
    }
  };
  return initialValues;
};
