import { getPurePhoneNumber } from "../../../../../../../utils/misc";

import { PhoneNumberType } from "../../../../constants";

export const getPfizerCopayIvFormInitialValues = ({ applicationForm, defaultNpi }) => {
  const patientState =
    applicationForm?.formData?.patient?.state === "" ? null : applicationForm?.formData?.patient?.state;

  const initialValues = {
    ...applicationForm?.formData,
    patient: {
      ...applicationForm?.formData?.patient,
      state: patientState,
      phoneType: applicationForm?.formData?.patient?.phoneType || PhoneNumberType.MOBILE,
      phone: applicationForm?.formData?.patient?.phone
        ? getPurePhoneNumber(applicationForm?.formData?.patient?.phone)
        : null
    },
    facility: {
      ...applicationForm?.formData?.facility,
      npi: applicationForm?.formData?.facility?.npi || defaultNpi
    }
  };

  return initialValues;
};
