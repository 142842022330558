export const defaultPatientState = {
  details: [],
  journey: [],
  opportunity: {},
  potentialSavings: [],
  personal: undefined,
  financial: undefined,
  medical: undefined,
  attachments: undefined,
  attachmentsCount: undefined,
  selectedJourneyEvent: undefined,
  potentialSavingsJourney: undefined,
  genericServices: [],
  latestTaskId: undefined,
  coverages: undefined,
  policies: undefined,
  policyServices: undefined,
  applicationForm: undefined,
  expressEnrollmentConfiguration: undefined,
  isFound: undefined
};

export const PublishStatus = Object.freeze({
  ANALYSIS_REQUESTED: "analysis_requested",
  PARTIAL: "partial"
});

export const DateDemographicsKeys = Object.freeze(["dob", "agreementSignatureDate", "incomeVerifiedDate"]);

export const REQUIRED_COVERAGE_TYPES = ({
  requiredCommercialInsurance,
  requiredMedicaidInsurance,
  requiredMedicareInsurance,
  requiredFederalInsurance,
  requiredUninsuredInsurance,
  requiredUnderinsuredInsurance
} = {}) => {
  return [
    { title: "Commercial", value: requiredCommercialInsurance },
    { title: "Medicaid", value: requiredMedicaidInsurance },
    { title: "Medicare", value: requiredMedicareInsurance },
    { title: "Federal", value: requiredFederalInsurance },
    { title: "Uninsured", value: requiredUninsuredInsurance },
    { title: "Underinsured", value: requiredUnderinsuredInsurance }
  ].filter(({ value }) => value);
};

export const rejectReasons = (t) => {
  return [
    {
      title: t("patientPotentialSavings.rejectReasonList.patient_not_eligible"),
      key: "0",
      selectable: false,
      children: [
        {
          title: t("patientPotentialSavings.rejectReasonList.patient_not_receiving"),
          key: "0-0"
        },
        {
          title: t("patientPotentialSavings.rejectReasonList.patient_not_active_treatment"),
          key: "0-1"
        },
        {
          title: t("patientPotentialSavings.rejectReasonList.demographic_related"),
          key: "0-2"
        },
        {
          title: t("patientPotentialSavings.rejectReasonList.diagnosis_not_eligible"),
          key: "0-3"
        },
        {
          title: t("patientPotentialSavings.rejectReasonList.coverage_not_eligible"),
          key: "0-4"
        }
      ]
    },
    { title: t("patientPotentialSavings.rejectReasonList.patient_sufficiently_covered"), key: "1" },
    { title: t("patientPotentialSavings.rejectReasonList.oop_limit_reached"), key: "2" },
    { title: t("patientPotentialSavings.rejectReasonList.patient_already_receiving_fn"), key: "3" },
    { title: t("patientPotentialSavings.rejectReasonList.patient_rejected_assistance"), key: "4" },
    { title: t("patientPotentialSavings.rejectReasonList.patient_receiving_assistance_different_facility"), key: "5" }
  ];
};

export const CREATE_NEW_PATIENT_STEPS = Object.freeze({
  demographics: "Demographic Step",
  whatNext: "Choose What You Want Next Step",
  findAssistancePrograms: "Find Assistance Programs Step"
});

export const UNDEFINED = "Undefined";
export const SINGLE = "Single";
export const MARRIED = "Married";
export const SEPARATED = "Separated";
export const DIVORCED = "Divorced";
export const WIDOWED = "Widowed";

export const SUPPORTED_COVERAGES = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary"
};

export const PHARMACY_KEY = "pharmacy";
