import { trackPendoEvent } from "./pendoUtils";

export const pendoEEUtils = (dispatch, application, eventName, eventTaken) => {
  const eventData = {
    eeTemplateName: application?.ApplicationFormConfigurationTemplateName,
    programName: application?.displayName,
    providerName: application?.potentialSaving?.APProvider,
    priority: application?.potentialSaving?.priority,
    tmPatientId: application?.potentialSaving?.patient,
    assosiatedDrug: application?.drugDisplayName,
    actionTaken: eventTaken
  };

  trackPendoEvent(dispatch, eventName, eventData);
};

export default { pendoEEUtils };
