import React from "react";

import { EEsignatureStatuses } from "../../../../../../constant/EEsignatureStatuses";
import { dateFormatter } from "../../../../../../utils/date";
import { formatToDecimal } from "../../../../../../utils/formaters";
import { SubmittedFormResultModal } from "../../../../PatientApplications/enrollTab/submission/SubmittedFormResultModal";
import { DynamicQuestionnaire } from "../../../components/DynamicQuestionnaire";
import { SaveButton } from "../../../header";
import { applyTrimFields } from "../../../utils/updateFormData";
import getPfizerCopayOralSidebarItems from "./sidebarItems";
import { DownloadPdfButton } from "../../../../PatientApplications/DownloadPdfButton";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../../constants";
import { CompleteFormButton } from "../../../CompleteFormButton";

const pfizerCopayOralConfigurations = {
  getSidebarItems: (t) => getPfizerCopayOralSidebarItems(t),
  activeFormSection: "#enrollment-form-eligibility-questionnaire",
  formId: "pfizer_copay_oral_smart_editor",
  resultId: "pfizer_copay_oral_enroll_result",
  fieldNames: {},
  getFormInitialValues: ({ applicationForm }) => applicationForm?.formData,
  getFormSectionComponents: () => (
    <DynamicQuestionnaire DividerText={"application_form_editor.genentech.copay.eligibility_questionnaire.header"} />
  ),
  getViewDetailsModal: (onCancel, visible) => <SubmittedFormResultModal onCancel={onCancel} visible={visible} />,
  applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_pfizer_copay_oral_submit"} {...props} />,
  headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.SUBMIT_FORM,
  saveButton: (props) => (
    <SaveButton onSave={(form) => applyTrimFields(form)} id={"EE_pfizer_copay_oral_save_pdf"} {...props} />
  ),
  shouldSaveAppear: () => true,
  getShowEnrollResult: (applicationForm) => applicationForm?.status === EEsignatureStatuses.SUBMITTED,
  prepareButtonId: "EE_pfizer_copay_oral_prepare_application_form",
  viewDetailsButtonId: "EE_pfizer_copay_oral_view_submission_details",
  applicationSupportsEEId: "application_supports_pfizer_copay_oral_EE",
  getEnrollResultData: ({ application, t }) => ({
    card_activation_date: dateFormatter(application.approvedDate),
    card_end_date: application.effectiveIndefinitely
      ? t("patientApplications.indefinitely")
      : dateFormatter(application.validThrough) || "--",
    member_id: application.memberId,
    bin: application.bin,
    group_number: application.group,
    total_grant_amount: formatToDecimal(application.approvedAmount),
    application_id: application.id
  }),
  getApprovedResultDescription: () => <DownloadPdfButton />,
  approvalDetailsButtonId: "EE_pfizer_copay_oral_view_approval_details",
  submissionStep: {
    description: (t) => t("patientApplications.enroll.application_form_submission.description"),
    getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.submit_button"),
    submitModal: {
      showNotice: true,
      attachments: {
        showAllowedFileTypesNotice: true
      }
    },
    getSubmitSuccessMessage: (t) => t("patientApplications.enroll.application_form_submission.submit_success_message")
  },
  afterSubmissionPayload: () => ({})
};

export default pfizerCopayOralConfigurations;
