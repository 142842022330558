import { getStateCode } from "../../../../../../constant/states";
import { UNSPECIFIED_MEMBER_ID, UNSPECIFIED_PAYER_NAME } from "../../../../../../constant/coverages";
import { getPurePhoneNumber } from "../../../../../../utils/misc";
import { insuranceMedicalName } from "./formSections/insuranceConfigurations";
import { drugAssistanceType } from "../../../components/SupportOptions";

const OTHER = "Other";

export const getGenentechCopayFormInitialValues = ({ applicationForm }) => {
  const DEFAULT_PHONE_TYPE = "mobile";

  const patientState =
    applicationForm?.formData?.patient?.state === "" ? null : applicationForm?.formData?.patient?.state;
  const insurance = applicationForm?.formData?.insurance;
  const insuranceTypeSelect = insurance?.medical?.planType ? OTHER : null;

  let insuranceNameSelect = null;
  if (insurance?.medical?.name && !insurance.medical.name.startsWith(UNSPECIFIED_PAYER_NAME)) {
    if (insuranceMedicalName.includes(insurance?.medical?.name)) {
      insuranceNameSelect = insurance?.medical?.name;
    } else {
      insuranceNameSelect = OTHER;
    }
  }

  let physicianState = applicationForm?.formData?.physician?.state || null;
  if (physicianState) physicianState = getStateCode(physicianState);

  let facilityState = applicationForm?.formData?.facility?.state || null;
  if (facilityState) facilityState = getStateCode(facilityState);

  const initialValues = {
    ...applicationForm?.formData,
    patient: {
      ...applicationForm?.formData?.patient,
      state: patientState,
      phoneType: applicationForm?.formData?.patient?.phoneType || DEFAULT_PHONE_TYPE,
      phone: applicationForm?.formData?.patient?.phone
        ? getPurePhoneNumber(applicationForm?.formData?.patient?.phone)
        : null
    },
    physician: {
      ...applicationForm?.formData?.physician,
      phone: applicationForm?.formData?.physician?.phone
        ? getPurePhoneNumber(applicationForm?.formData?.physician?.phone)
        : null,
      lastName: applicationForm?.formData?.physician?.lastName || applicationForm?.formData?.physician?.firstName,
      state: physicianState
    },
    facility: {
      ...applicationForm?.formData?.facility,
      phone: applicationForm?.formData?.facility?.phone
        ? getPurePhoneNumber(applicationForm?.formData?.facility?.phone)
        : null,
      state: facilityState
    },
    insurance: {
      ...insurance,
      medical: {
        ...insurance?.medical,
        selectPlanType: insuranceTypeSelect,
        selectName: insuranceNameSelect,
        memberId: insurance?.medical?.memberId?.startsWith(UNSPECIFIED_MEMBER_ID) ? null : insurance?.medical?.memberId
      }
    },
    assistanceType: applicationForm?.formData?.assistanceType ?? [drugAssistanceType]
  };

  return initialValues;
};
