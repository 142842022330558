import { put } from "redux-saga/effects";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";
import ACTION from "../action";
import { CallerType, ApiCallerMap, api } from "../../api/api";
import { isServerError } from "@tailormed/common-client/util/serverError";
import { getAuthInstance } from "@tailormed/common-client/services/auth";
import { oktaConfig } from "../../constant/okta";

function* networkAction(methodName, ...args) {
  let result = null;
  yield put(ACTION.setNetworkAction(methodName, true));
  try {
    result = yield api(methodName)(...args);
    yield put(ACTION.setNetworkAction(methodName, false));
  } catch (err) {
    if (err.config?.method === "head") {
      throw err;
    }
    if (err.response?.status === 431) {
      console.error(`request failed with status code 431 (Request Header Fields Too Large): ${err}`);
    }
    const errMessage = err.response?.data?.errors ? err.response.data.errors[0].message : err.message;
    if (isServerError(err)) {
      yield put(ACTION.serverError(err.response.status, errMessage));
    } else {
      if (err.response?.status !== 404) ErrorMessage(`${err.name}: ${errMessage}`);
      yield put(ACTION.setNetworkAction(methodName, false, err.message, err.name));
    }
  }

  return result;
}

function* callAuthApi(methodName, ...args) {
  const oktaAuth = getAuthInstance(oktaConfig.redirectUri);
  const token = oktaAuth.getAccessToken();

  if (!token) {
    throw Error("Missing Auth Key");
  }

  const result = yield networkAction(methodName, token, ...args);
  return result;
}

const ApiMethodMap = Object.freeze({
  [CallerType.Generic]: networkAction,
  [CallerType.Auth]: callAuthApi
});

export function* callApi(apiName, ...args) {
  const methodType = ApiCallerMap[apiName];
  const caller = ApiMethodMap[methodType];
  const result = yield caller(apiName, ...args);
  return result;
}
